import { handleActions } from 'redux-actions';
import {
    setListsByUser,
    setFeaturedLists,
    setLatestLists
} from '../../actions/Lists/listsActions';

const defaultState = {
    listsByUser: null,
    featured:null,
    latest:null
};

export default handleActions(
  {
      [setListsByUser](state, { payload }) {
          return { ...state, listsByUser: payload };
      },[setFeaturedLists](state, { payload }) {
          return { ...state, featured: payload };
      },[setLatestLists](state, { payload }) {
          return { ...state, latest: payload };
      }
  },
  defaultState,
);
