import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModalSpecs from './components/ModalSpecs';
import ModalFeatures from './components/ModalFeatures';
import SpecsTable from "./components/SpecsTable";
import FeaturesTable from "./components/FeaturesTable";
import { Col, Row, Alert, Button, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SpecsAndFeatures = ({dispatch, data, features, specifications, rtl}) => {

    return (
        <>
            <Row>
                <Col xs={'12'} sm={'6'} className={'pb-4'}>
                    <div className={'pb-4'}>
                        <ModalSpecs />
                    </div>
                    <SpecsTable/>
                </Col>
                <Col xs={'12'} sm={'6'} className={'pb-4'}>
                    <div className={'pb-4'}>
                        <ModalFeatures/>
                    </div>
                    <FeaturesTable/>
                </Col>
            </Row>
        </>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    data:state.product.productData,
    features:state.product.features,
    specifications:state.product.specifications,
}))(SpecsAndFeatures);