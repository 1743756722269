import Http from '../../containers/App/Http';
import store from '../../containers/App/store'
import {setProducts} from "../../redux/actions/productsActions";


export default class Posts{

    getPostsByDate(formData){
        return Http.post("/pts/getPostsByDate",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    }

}