import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash, faUpload} from '@fortawesome/free-solid-svg-icons';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Alert from '../Alert';
import {connect} from "react-redux";
import ModelImages from "../../../Models/Images/Images";

const MultipleImageUploadV2 = ({path, existingImages,type,id,returnType,handleSetFeatured}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [images, setImages] = useState(existingImages??[]);
    const [basePath, setBasePath] = useState((path)?path:process.env.REACT_APP_BASE_API_URL);

    const im = new ModelImages();

    const handleFileSelect = async (files) => {
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const allowedFileSize = 5 * 1024 * 1024; // 5 MB
        const maxWidth = 1920;

        const filteredFiles = files.filter(
            (file) =>
                allowedFileTypes.includes(file.type) &&
                file.size <= allowedFileSize &&
                !selectedFiles.includes(file)
        );

        setSelectedFiles([...selectedFiles, ...filteredFiles]);

        const oversizedFiles = files.filter((file) => file.size > allowedFileSize);
        if (oversizedFiles.length > 0)
            setErrorMessage(`The following files are too large: ${oversizedFiles.map((file) => file.name).join(', ')}`);

        const resizedFiles = await Promise.all(
            filteredFiles.map(async (file) => {
                if (file.type.startsWith('image/')) {
                    const image = await loadImage(file);
                    if (image.width > maxWidth) {
                        const resizedImage = await resizeImage(image, maxWidth);
                        const resizedFile = await createFileFromImage(resizedImage, file.name, file.type);
                        return resizedFile;
                    }
                }
                return file;
            })
        );

        for (const file of resizedFiles) {
            try {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('type', type);
                formData.append('id', id);
                formData.append('returnType', returnType);
                im.uploadImage(formData).then((res)=>{

                    // REMOVE THE LOCAL VIEW

                });

            } catch (error) {
                console.error(error);
                setErrorMessage('There was an error uploading your files. Please try again.');
            }
        }
    };

    const loadImage = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error('Failed to load image file'));
            img.src = URL.createObjectURL(file);
        });
    };

    const resizeImage = (image, maxWidth) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const aspectRatio = image.width / image.height;
            const canvasWidth = maxWidth;
            const canvasHeight = Math.round(canvasWidth / aspectRatio);

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);

            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg', 0.8);
        });
    };

    const createFileFromImage = (image, name, type) => {
        return new File([image], name, { type });
    };


    const handleFileDelete = (file) => {
        try {
            const formData = new FormData();
            formData.append('i_id', file.i_id);
            formData.append('type', type);
            formData.append('id', id);
            formData.append('returnType', returnType);
            im.deleteImage(formData).then((res)=>{

                // REMOVE THE LOCAL VIEW

            });

        } catch (error) {
            console.error(error);
            setErrorMessage('There was an error uploading your files. Please try again.');
        }
    };

    // const renderSelectedFiles = () => {
    //     return selectedFiles.map((file, index) => (
    //         <Col key={index} xs="12" md="6" lg="3">
    //             <div className="d-flex flex-column justify-content-center align-items-center h-100 border border-dashed p-3">
    //                 {file.type.startsWith('image/') && (
    //                     <img
    //                         src={URL.createObjectURL(file)}
    //                         alt={file.name}
    //                         className="img-fluid mb-3"
    //                         style={{ maxWidth: '100%' }}
    //                     />
    //                 )}
    //                 <div className="text-center">
    //                     <p>{file.name}</p>
    //                     <p>{file.size} bytes</p>
    //                 </div>
    //                 <div className="mt-3">
    //                     <Button
    //                         color="danger"
    //                         size="sm"
    //                         className="mr-3"
    //                         onClick={() => handleFileDelete(file)}
    //                     >
    //                         <FontAwesomeIcon icon={faTrash} />
    //                     </Button>
    //                     {/*<Button color="secondary" size="sm" onClick={handleSetFeatured}>*/}
    //                     {/*    Set Featured*/}
    //                     {/*</Button>*/}
    //                 </div>
    //             </div>
    //         </Col>
    //     ));
    // };

    const renderImages = () => {
        return images.map((file, index) => (
            <Col key={index} xs="12" md="6" lg="3">
                <div className="d-flex flex-column justify-content-center align-items-center h-100 border border-dashed p-3">
                    <img
                        src={basePath+file.i_filename} alt={file.i_name}
                        className="img-fluid mb-3"
                        style={{ maxWidth: '100%' }}
                    />

                    <div className="text-center">
                        <p>{file.i_name}</p>
                    </div>
                    <div className="mt-3">
                        <Button
                            color="danger"
                            size="sm"
                            className="mr-3"
                            onClick={() => handleFileDelete(file)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button color="secondary" size="sm" onClick={()=>handleSetFeatured(file)}>
                            Set Featured
                        </Button>
                    </div>
                </div>
            </Col>
        ));
    }

    const handleAlertDismiss = () => {
        setErrorMessage(false);
    };

    return (
        <Container>
            {errorMessage && (
                <Row>
                    <Col xs={12}>
                        <Alert color="danger" toggle={handleAlertDismiss}>
                            {errorMessage}
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Dropzone onDrop={handleFileSelect} accept="image/*">
                        {({ getRootProps, getInputProps }) => (
                            <section className="p-3 border border-dashed text-center">
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p><FontAwesomeIcon icon={faUpload}/> Drag 'n' drop some files here, or click to select files</p>
                                </div>
                            </section>

                        )}
                    </Dropzone>
                </Col>
            </Row>
            <Row className="mt-3">
                <>
                {renderImages()}
                </>
            </Row>
        </Container>
    );
};
export default connect(state => ({
    user : state.user.main
}))(MultipleImageUploadV2);