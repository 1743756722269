import Http from '../../containers/App/Http';
import store from '../../containers/App/store'
import {setProducts} from "../../redux/actions/productsActions";


export default class CronJob{

    getExecutedByDate(formData){
        return Http.post("/cjs/getExecutedByDate",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    }

    getJobData(formData){
        return Http.post("/cj/getJobData",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    }

    executeJob(formData){
        return Http.post(process.env.REACT_APP_MAIN_APP_API_URL + "/api/v1/executeJob?apiKey="+process.env.REACT_APP_MAIN_APP_API_KEY,formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    }


}