import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {
    setProduct,
    setFeatures,
    setSpecifications,
    setPriceTrackerData,
    setRatingTrackerData,
    setLinks,
    setCategories,
    setImages,
    setPriceExtended,
    setRatingExtended,
    setCategoriesExtended
} from "../redux/actions/productActions";

export default class Product{

    getProductData(productId,priceHistoryRange,ratingHistoryRange){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('productId',productId);
        formData.append('priceHistoryRange',priceHistoryRange);
        formData.append('ratingHistoryRange',ratingHistoryRange);

        return Http.post("/p/getProduct",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setProduct(response.data.data[0][0]));
            await store.dispatch(setLinks(response.data.data[1]));
            await store.dispatch(setSpecifications(response.data.data[2]));
            await store.dispatch(setFeatures(response.data.data[3]));
            await store.dispatch(setPriceTrackerData(response.data.data[4]));
            await store.dispatch(setRatingTrackerData(response.data.data[5]));
            await store.dispatch(setImages(response.data.data[0][0]['p_images']));
            await store.dispatch(setPriceExtended(response.data.data[11]));
            await store.dispatch(setRatingExtended(response.data.data[7]));
            // await store.dispatch(setCategoriesExtended(response.data.data[8]));
            return response;
        });
    };

    getProductDataForEditor(productId){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('productId',productId);

        return Http.post("/p/getProductForEditor",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setProduct(response.data.data[0][0]));
            await store.dispatch(setLinks(response.data.data[1]));
            await store.dispatch(setSpecifications(response.data.data[2]));
            await store.dispatch(setFeatures(response.data.data[3]));
            await store.dispatch(setPriceTrackerData(response.data.data[4]));
            await store.dispatch(setRatingTrackerData(response.data.data[5]));
            await store.dispatch(setImages(response.data.data[0][0]['p_images']));
            return response;
        });
    };

    addSpec(formData){

        return Http.post("/p/addSpecification",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    addFeature(formData){

        return Http.post("/p/addFeature",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    // updateSpec(productId){
    //     // FETCH THE VENUE INFO
    //     const formData = new FormData();
    //     formData.append('productId',productId);
    //
    //     return Http.post("/p/getProduct",formData).then(async response => {
    //         if (response.data.status === 'failed')
    //             return response;
    //
    //         await store.dispatch(setProduct(response.data.data[0][0]));
    //         await store.dispatch(setFeatures(response.data.data[1]));
    //         await store.dispatch(setSpecifications(response.data.data[2]));
    //         return response;
    //     });
    // };
    //
    // updateFeature(productId){
    //     // FETCH THE VENUE INFO
    //     const formData = new FormData();
    //     formData.append('productId',productId);
    //
    //     return Http.post("/p/getProduct",formData).then(async response => {
    //         if (response.data.status === 'failed')
    //             return response;
    //
    //         await store.dispatch(setProduct(response.data.data[0][0]));
    //         await store.dispatch(setFeatures(response.data.data[1]));
    //         await store.dispatch(setSpecifications(response.data.data[2]));
    //         return response;
    //     });
    // };

    updateProduct(formData){

        return Http.post("/p/updateProduct",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    generateArticle(){
        return Http.post("/ag/generate").then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    setFeaturedImage(formData){
        return Http.post(process.env.REACT_APP_BASE_API_URL +"/p/setFeaturedImage",formData).then(response => {
            return response;
        });
    }
}