import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {
    Col,
    Row,
    Alert,
    Button,
    ButtonToolbar,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Card,
    CardBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WhereToBuyTable from "./components/WhereToBuyTable";

const WhereToBuy = ({dispatch, data, features, specifications, rtl}) => {

    return (

            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">Where To Buy</h5>
                    </div>
                    <div>
                        <WhereToBuyTable/>
                    </div>
                </CardBody>
            </Card>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    data:state.product.productData,
    features:state.product.features,
    specifications:state.product.specifications,
    // users:state.users.users,
}))(WhereToBuy);