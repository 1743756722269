import Http from '../../containers/App/Http';
import store from '../../containers/App/store'
import {
    setListData,
    setListProducts,
    setListImages
} from "../../redux/actions/Lists/listActions";

export default class List{

    getListByPermalink(permalink){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('permalink',permalink);
        // formData.append('priceHistoryRange',priceHistoryRange);
        // formData.append('ratingHistoryRange',ratingHistoryRange);

        return Http.post("/l/getListByPermalink",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setListData(response.data.data[0]));
            await store.dispatch(setListProducts(response.data.data[1]));
            if(response.data.data.length > 1)
                await store.dispatch(setListImages(response.data.data[2]));

            return response;
        });
    };

    editList(formData){
        // FETCH THE VENUE INFO

        // formData.append('permalink',permalink);
        // formData.append('priceHistoryRange',priceHistoryRange);
        // formData.append('ratingHistoryRange',ratingHistoryRange);

        return Http.post("/s_l/editList",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setListData(response.data.data[0]));

            return response;
        });
    }

    uploadListImage(formData){
        // FETCH THE VENUE INFO

        // formData.append('permalink',permalink);
        // formData.append('priceHistoryRange',priceHistoryRange);
        // formData.append('ratingHistoryRange',ratingHistoryRange);

        return Http.post("/s_l/uploadImage",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setListImages(response.data.data[0]));

            return response;
        });
    }

    deleteListImage(formData){

        return Http.post("/s_l/deleteImage",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setListImages(response.data.data[0]));

            return response;
        });
    }

    setFeaturedListImage(formData){
        // FETCH THE VENUE INFO

        // formData.append('permalink',permalink);
        // formData.append('priceHistoryRange',priceHistoryRange);
        // formData.append('ratingHistoryRange',ratingHistoryRange);

        return Http.post("/s_l/setFeaturedImage",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            // SHOULD THE RESPONSE GET NEW LIST DATA AND INCLUDE THE FILENAME IN THERE?
            await store.dispatch(setListData(response.data.data[0]));
            await store.dispatch(setListImages(response.data.data[1]));

            return response;
        });
    }

}