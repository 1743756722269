import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faStarHalf} from '@fortawesome/fontawesome-free-solid';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons'
import {Badge} from "reactstrap";

const SidebarContent = ({ onClick, changeToDark, changeToLight }) => {
  const handleHideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
          <SidebarLink title="Home" icon="home" route="/" onClick={handleHideSidebar} />
          <SidebarLink title="Category Mgmt" icon="home" route="/categories" onClick={handleHideSidebar} />
          <SidebarLink title="Cron Jobs" icon="home" route="/cjs" onClick={handleHideSidebar} />
          <SidebarLink title="My Lists" icon="heart" route="/lists" onClick={handleHideSidebar} />
          <SidebarLink title="Logs" icon="exit" route="/logs" onClick={handleHideSidebar} />
          <SidebarLink title="Posts" icon="exit" route="/pts" onClick={handleHideSidebar} />
          <SidebarLink title="Ques" icon="flag" route="/qs" onClick={handleHideSidebar} />
          <SidebarLink title="WP Sites" icon="flag" route="/wps" onClick={handleHideSidebar} />
        <SidebarLink title="Products" icon="exit" route="/products" onClick={handleHideSidebar} />
      </ul>
      <ul className="sidebar__block">
          <li className="sidebar__link">
              <FontAwesomeIcon icon={faPlus} />
              <p className="sidebar__link-title">
                   Create List
              </p>
          </li>
        <SidebarCategory title="Layout" icon="layers">
          <button type="button" className="sidebar__link" onClick={changeToLight}>
            <p className="sidebar__link-title">Light Theme</p>
          </button>
          <button type="button" className="sidebar__link" onClick={changeToDark}>
            <p className="sidebar__link-title">Dark Theme</p>
          </button>
        </SidebarCategory>
      </ul>
      {/*<ul className="sidebar__block">*/}
        {/*<SidebarCategory title="Example Pages" icon="diamond">*/}
          {/*<SidebarLink title="Page one" route="/pages/one" onClick={handleHideSidebar} />*/}
          {/*<SidebarLink title="Page two" route="/pages/two" onClick={handleHideSidebar} />*/}
        {/*</SidebarCategory>*/}
      {/*</ul>*/}
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
