import React,{ useEffect,useState } from 'react';
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import {connect} from "react-redux";
import ModelProduct from '../../../Models/Product';
import { faUserSlash,faPencilAlt,faEye,faTimes } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../shared/components/Notification";
import ReactTableBase from "../table/ReactTableBase";

const ProductsTable = ({ dispatch, productsData ,columnsStructure,theme,rtl}) => {
    const [rows, setData] = useState(productsData);
    const [columns, setColumns] = useState(columnsStructure);
    const [isResizable, setIsResizable] = useState(true);
    const [isSortable, setIsSortable] = useState(true);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(true);


    const history = useHistory();
    const [isTableLoaded,setLoaded] = useState(false);
    const vm = new ModelProduct();

    const redirect = (link) =>{
        history.push(link);
    };

    useEffect(()=>{
        setLoaded(false);
        if(typeof productsData !== 'undefined' && productsData !== null){
            setData(productsData);
            setLoaded(true);
        }
    },[productsData,columnsStructure]);

    const tableConfig = {
        isResizable,
        isSortable,
        withPagination,
        withSearchEngine,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search...',
    };

    return (
        isTableLoaded &&
            <ReactTableBase
                key={withSearchEngine || isResizable ? 'modified' : 'common'}
                columns={columns}
                data={rows}
                tableConfig={tableConfig}
            />
    );
};

export default connect(state => ({
    loadedProducts:state.products.loadedProducts,
    rtl: state.rtl,
    theme: state.theme
}))(ProductsTable);
