import React,{useState,useEffect} from "react";
import {connect} from "react-redux";
import { Col, Row } from 'reactstrap';
import ModelProduct from '../../../../Models/Product';
import ModelCategories from '../../../../Models/Categories';
import {showNotification} from "../../../../shared/components/Notification";

const Main = ({dispatch,productData,theme,rtl}) => {

    const vm = new ModelProduct();
    const cm = new ModelCategories();
    const [categories,setCategories]=useState(false);
    const [readyForData,setReadyForData]=useState(false);


    useEffect(() =>{
        // showNotification(theme,'success',rtl,'Update Product',"hello");
        cm.getAllCategories().then(async (response)=>{
            await setCategories(response.data.data);
            setReadyForData(true);
        });
    },[]);

    useEffect(() =>{
            if(!readyForData)
                return;

            Object.keys(productData).forEach(key => {
                document.querySelectorAll('input[name='+key+']').forEach((el)=>{
                    el.value = productData[key];
                });
                document.querySelectorAll('select[name='+key+']').forEach((el)=>{
                    el.value = productData[key];
                });
                document.querySelectorAll('textarea[name='+key+']').forEach((el)=>{
                    el.value = productData[key];
                });
            });
            // document.querySelector('input[type=checkbox][name=v_outdoor_space_available]').checked = productData.v_outdoor_space_available === 'Y';


            // let venueTypeIds = productData.vt_ids.split(",");
            // // document.querySelectorAll('input[type=checkbox][name*=vt_ids][value='+id.trim()+']').forEach((x)=>console.log(x))
            // venueTypeIds.forEach(function(id){
            //
            //     // debugger
            //     document.querySelectorAll('input[type=checkbox][name*=vt_ids]').forEach((vtId)=>{
            //
            //         if(vtId.value === id.trim())
            //             vtId.checked =true;
            //     });
            //     //.checked =true;
            //     // $('#venueEditForm [name*=vt_ids][value='+id.trim()+']').prop('checked', true);
            //
            //     // document.querySelectorAll('input[type=checkbox]');
            // });



    },[readyForData]);

    // useEffect(() =>{
        // vm.getProductDataForEditor(productData.p_id).then(response => {
        //     if(response.data.status === 'failed'){
        //         showNotification(theme,'danger',rtl,'Get Main',response.data.msg);
        //         return;
        //     }
        //
        //     let MainContainer = document.getElementById("MainContainer");
        //
        //     MainContainer.innerHTML = "";
        //     response.data.data.Main.forEach(function(item){
        //
        //         let el= document.createElement('div');
        //         el.setAttribute('class',"form-check");
        //         el.innerHTML = `<input type="checkbox" class="form-check-input" data-venue-amenity-id="`+item.va_id+`" name="Main[]" value="`+item.va_id+`" id="Main`+item.va_id+`">
        //             <label class="form-check-label checkbox-btn__label" for="Main`+item.va_id+`">`+item.va_name+`</label>`;
        //         MainContainer.insertBefore(el, MainContainer.firstChild);
        //     });
        //
        //     vm.getVenueMain(productData.p_id).then(response=>{
        //         if(response.data.status === 'failed'){
        //             showNotification(theme,'danger',rtl,'Get Main',response.data.msg);
        //             return;
        //         }
        //
        //         if(response.data.data.Main.length === 0)
        //             return;
        //         response.data.data.Main.forEach((am)=>{
        //             // document.querySelector("[data-venue-amenity-id="+am.va_id+"]").checked = true;
        //             document.querySelector("[data-venue-amenity-id='"+am.va_id+"']").checked = true;
        //         });
        //     });
        //
        //
        // });
    // },[productData]);

    const updateProduct = (e) =>{
        e.preventDefault();
        let formData = new FormData(document.getElementById('ProductEditForm'));

        vm.updateProduct(formData).then((response)=>{
            if(response.data.status==='failed'){
                showNotification(theme,'danger',rtl,'Update Product',response.data.msg);
                return;
            }

            showNotification(theme,'success',rtl,'Update Product',response.data.msg);
            return;
        });

    };

    return (
        <div className={"col-12 p-0"}>
            <form id="ProductEditForm" className={"form--horizontal form"}>
                <div className="form__form-group row mt-1 mb-2">
                    <label htmlFor="p_id" className="col-sm-2 form__form-group-label col-form-label">ID</label>
                    <div className="col-sm-4">
                        <input type="text" className="form-control-sm" id="p_id" name="p_id"   readOnly/>
                    </div>
                    <label htmlFor="p_active" className="col-sm-2 form__form-group-label col-form-label">Active</label>
                    <div className="col-sm-4">
                        <select className="form-control form-control-sm" name="p_active">
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                </div>
                <div className="form__form-group row mt-1 mb-2">
                    <label htmlFor="p_created" className="col-sm-2 form__form-group-label col-form-label">Created</label>
                    <div className="col-sm-4">
                        <input type="text" className="form-control-sm" id="p_created" name="p_created" disabled={true}/>
                    </div>
                    <label htmlFor="p_modified" className="col-sm-2 form__form-group-label col-form-label">Modified</label>
                    <div className="col-sm-4">
                        <input type="text" className="form-control-sm" id="p_modified" name="p_modified" disabled={true}/>
                    </div>
                </div>
                <div className="form__form-group row mt-1 mb-2">
                    <label htmlFor="p_rating" className="col-sm-2 form__form-group-label col-form-label">Rating</label>
                    <div className="col-sm-4">
                        <input type="text" className="form-control-sm" id="p_rating" name="p_rating"  />
                    </div>
                    <label htmlFor="p_in_stock" className="col-sm-2 form__form-group-label col-form-label">In Stock</label>
                    <div className="col-sm-4">
                        <select className="form-control form-control-sm" name="p_in_stock">
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                </div>
                <div className="form__form-group row mt-1 mb-2">
                    <label htmlFor="p_last_price" className="col-sm-2 form__form-group-label col-form-label">Last Price</label>
                    <div className="col-sm-4">
                        <input type="text" className="form-control-sm" id="p_last_price" name="p_last_price"/>
                    </div>
                    <label htmlFor="p_msrp" className="col-sm-2 form__form-group-label col-form-label">MSRP</label>
                    <div className="col-sm-4">
                        <input type="text" className="form-control-sm" id="p_msrp" name="p_msrp"  />
                    </div>
                </div>
                <div className="form__form-group row mt-1 mb-2">
                    <label htmlFor="p_name" className="col-sm-2 form__form-group-label col-form-label">Name</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control-sm" id="p_name" name="p_name" />
                    </div>
                </div>
                <div className="form__form-group row mt-1 mb-2">
                    <label htmlFor="p_model" className="col-sm-2 form__form-group-label col-form-label">Model</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control-sm" id="p_model" name="p_model" />
                    </div>
                </div>
                <div className="form__form-group row mt-1 mb-2">
                    <label htmlFor="p_scrape_frequency" className="col-sm-2 form__form-group-label col-form-label">Scrape Freq</label>
                    <div className="col-sm-10">
                        <select className="form-control form-control-sm" name="p_scrape_frequency" >
                            <option value="h">Hourly</option>
                            <option value="d">Daily</option>
                            <option value="3">3 Days</option>
                            <option value="w">Weekly</option>
                            <option value="m">Monthly</option>
                        </select>
                    </div>
                </div>
                <div className="form__form-group row mt-1 mb-2">
                    <label htmlFor="pc_id" className="col-sm-2 form__form-group-label col-form-label">Category</label>
                    <div className="col-sm-10">
                        <select className="form-control form-control-sm" name="pc_id" >
                            {categories!== false && categories.map((category,i)=>(
                                <option key={i} value={category.pc_id}>{category.pc_name}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {/*<div className="form__form-group row mt-3">*/}
                    {/*<label htmlFor="v_website" className="col-sm-1 form__form-group-label col-form-label">Website</label>*/}
                    {/*<div className="col-sm-5">*/}
                        {/*<input type="text" className="form-control-sm" id="v_website" name="v_website" />*/}
                    {/*</div>*/}
                    {/*<label htmlFor="v_phone" className="col-sm-1 form__form-group-label col-form-label">Phone</label>*/}
                    {/*<div className="col-sm-5">*/}
                        {/*<input type="text" className="form-control-sm" id="v_phone" name="v_phone" />*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*<div className="row form__form-group">*/}
                    {/*<div className="form__form-group col-sm-6">*/}
                        {/*<label className={"form__form-group-label"} htmlFor="v_description">Description</label>*/}
                        {/*<textarea className="form-control-sm" id="v_description" name="v_description" rows="9"/>*/}
                    {/*</div>*/}
                    {/*<div className="form__form-group col-sm-6">*/}
                        {/*<label className={"form__form-group-label"} htmlFor="v_pricing">Pricing</label>*/}
                        {/*<textarea className="form-control-sm" id="v_pricing" name="v_pricing" rows="9"/>*/}
                    {/*</div>*/}
                {/*</div>*/}
                <Row className={'form__form-group'}>
                    <Col xs={'12'} sm={'12'} className={'text-right'}>
                        <hr/>
                        <button className="btn btn-primary" type="button" onClick={updateProduct}>Update</button>
                    </Col>
                </Row>



            </form>
        </div>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    productData:state.product.productData,
    // showVenueMenu:state.venue.showVenueMenu
}))(Main);