import React,{ useEffect,useState } from 'react';
import Panel from "../../Panel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelSubmissions from '../../../../Models/CronJobs/CronJobs';
import {Link, useHistory} from "react-router-dom";
import {faExternalLinkAlt, faEye, faHotel, faPen} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "rc-tooltip";
import DataTable from "../../DataTable";
import {Modal} from "reactstrap";

const CronJobTable = ({ dispatch, title, limit,cj_id,rtl}) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const [modal, setModal] = useState(false);
    const [logData, setLogData] = useState('');
    const um = new ModelSubmissions();
    const history = useHistory();

    const redirect = (link) =>{
        history.push(link);
    };

    const toggle = () => setModal(!modal); // Function to toggle modal on and off

    const showLog = (log) => {
        setLogData(log); // Set the log data to be displayed
        toggle(); // Open the modal
    };

    const columns =[
        {
            Header: "View Log",
            Cell: ({ cell }) => {
                return (
                    <>
                        <button
                            className={"btn btn-info btn-sm m-0 mr-2 mt-1 pt-1 pb-1 pl-2 pr-1"}
                            onClick={() => showLog(cell.row.original.log)} // Open the modal and set log data when button is clicked
                        >
                            View Log
                        </button>
                    </>
                )
            }
        },{
            width: 300,
            Header: "Ran By",
            accessor: "t_created_by"
        },{
            Header: "Ran Date",
            accessor: "t_creation_date_display"
        },{
            Header: "Ran Time",
            accessor: "t_creation_time_display"
        },{
            Header: 'ID',
            accessor: 't_id',
            width: 100
        },{
            Header: 'Status',
            accessor: 't_status',
            width: 150
        }
    ];

    return (
        <>
            <Panel xl={12} lg={12} md={12} title={title ?? "Submissions"} subhead="Table of Cron Jobs">
                <div>
                    <DataTable columns={columns} endpoint={"/cj/getJobHistoryForDataTable"} additionalData={{ 'cj_id': cj_id }} />
                </div>
            </Panel>

            <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="lg">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">Log Data</h4>
                </div>
                <div className="modal__body">
                    <pre>{logData}</pre> {/* Display log data in preformatted text */}
                </div>
            </Modal>
        </>
    );
};

export default connect(state => ({
    rtl: state.rtl
}))(CronJobTable);
