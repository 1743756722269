import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {
    setStatistics,
    setCategory,
    setCategoryImages
} from "../redux/actions/categoryActions";

export default class Category{

    getCategoryPageData(categoryPermalink){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('pc_permalink',categoryPermalink);

        return Http.post("/c/getCategoryPageData",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setCategory(response.data.data.category));
            await store.dispatch(setStatistics(response.data.data.statistics));
            await store.dispatch(setCategoryImages(response.data.data.images));

            return response;
        });
    };

    setCategoryFeaturedStatus(id, statusBool){
        // FETCH THE VENUE INFO
        let status=(statusBool===true)?'Y':'N';
        const formData = new FormData();
        formData.append('status',status);
        formData.append('pc_id',id);

        return Http.post("/c/setCategoryFeaturedStatus",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            // await store.dispatch(setCategory(response.data.data.category));
            // await store.dispatch(setStatistics(response.data.data.statistics));

            return response;
        });
    };

    setFeaturedImage(id, i_id){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('i_id',i_id);
        formData.append('pc_id',id);

        return Http.post("/c/setFeaturedImage",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            // await store.dispatch(setCategory(response.data.data.category));
            // await store.dispatch(setStatistics(response.data.data.statistics));

            return response;
        });
    };

}