import React,{ useState,useEffect } from 'react';
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {useHistory} from "react-router-dom";
import ModelProduct from '../../Models/Products';
import ModelCategories from '../../Models/Categories';
import ListsTable from "../../shared/components/ListsTable";
import {connect} from "react-redux";

const Categories = () => {

    const mp = new ModelProduct();
    const ml = new ModelCategories();
    // const [pData,setData] = useState(null);
    const [isLoaded,setLoaded] = useState(false);
    const [data,setData] = useState(null);

    useEffect(() => {
        ml.getAllCategories().then((res)=>{
            if (res.data.status === 'failed')
                return;
            setData(res.data.data);
        });
    },[]);

    const history = useHistory();

    const redirect = (link) =>{
        history.push(link);
    };

    const columns =[
        {
            width: 300,
            Header: "Link",
            Cell: ({ cell }) => {
                return (
                    <p style={{cursor:'pointer'}} className={ "m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); redirect('/c/'+cell.row.original.pc_permalink)}}>
                        {cell.row.original.pc_name}
                    </p>
                )
            }
        },
        {
            Header: 'ID',
            accessor: 'pc_id',
            width: 150
        },
        {
            Header: 'Name',
            accessor: 'pc_name',
            width: 150
        },
        {
            Header: 'Created',
            accessor: 'pc_created',
            width: 150
        },
        {
            Header: 'Featured',
            accessor: 'pc_featured',
            width: 150
        }

    ];

    return (
        <Container className="Home">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Categories</h3>
                </Col>
            </Row>
            <Row>
                <Card>
                    <CardBody>
                        <ListsTable data={data} allowSearch={true} columnsStructure={columns}  />
                    </CardBody>
                </Card>

            </Row>
        </Container>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme
}))(Categories);