import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import Product from "../Product";
import ProductEdit from "../ProductEdit";
import Logs from "../Logs";
import Category from "../Category";
import Categories from "../Categories";
import Home from "../Home";
import ProtectedRoute from './ProtectedRoute';
import Lists from '../Lists';
import List from '../List';
import CronJobs from '../CronJobs/CronJobs';
import CronJob from '../CronJobs/CronJob';
import CreateCronJob from "../CronJobs/CreateCronJob";
import Posts from "../Posts/Posts";
import WPSites from "../WPSites/WPSites";
import AddWPSite from "../WPSites/AddWPSite";
import Ques from "../Ques";

const Pages = () => (
  <Switch>
    {/*<Route path="/pages/one" component={ExamplePageOne} />*/}
    {/*<Route path="/pages/two" component={ExamplePageTwo} />*/}
  </Switch>
);

const wrappedRoutes = () => (
    <div>
        <Layout />
        <div className="container__wrap">
            <ProtectedRoute exact path="/" component={Home} />
            <Route path="/pages" component={Pages} />
            <Route path="/products" component={Home} />
            <ProtectedRoute exact path="/qs" component={Ques} />
            <ProtectedRoute exact path="/wps" component={WPSites} />
            <ProtectedRoute exact path="/wps/add" component={AddWPSite} />
            <ProtectedRoute exact path="/pts" component={Posts} />
            <ProtectedRoute exact path="/cjs/create" component={CreateCronJob} />
            <ProtectedRoute exact path="/cjs" component={CronJobs} />
            <ProtectedRoute path="/cj/:cjId" component={CronJob} />
            <Route path="/lists" component={Lists} />
            <Route path="/l/:permalink" component={List} />
            <Route exact path="/product/:plink" component={Product} />
            <Route exact path="/c/:plink" component={Category} />
            <Route exact path="/categories" component={Categories} />
            <ProtectedRoute exact path="/logs" component={Logs} />
            <ProtectedRoute path="/edit/:productId" component={ProductEdit} />
        </div>
    </div>
);

const protectedRoutes = () => (
    <div>
        <Layout />
        <div className="container__wrap">
            <Route exact path="/" component={Home} />
            <Route path="/pages" component={Pages} />
            <Route path="/products" component={Dashboard} />
            <Route exact path="/product/:plink" component={Product} />
            <Route exact path="/c/:plink" component={Category} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute path="/edit/:productId" component={ProductEdit} />
        </div>
    </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;