import Http from '../../containers/App/Http';
import store from '../../containers/App/store'
import {setProducts} from "../../redux/actions/productsActions";


export default class CronJobs{

    getExecutedByDate(formData){
        return Http.post("/cjs/getExecutedByDate",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    }

    createJob(){
        return Http.post("/cjs/createJob",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    }


}