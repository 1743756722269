import React,{ useState,useEffect } from 'react';
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {useParams} from 'react-router-dom';
import ModelProduct from '../../Models/Products';
import GeneralLog from "./components/GeneralLog";
import DefaultErrorLog from "./components/DefaultErrorLog";
import WriterLog from "./components/WriterLog";
import CronJobsLog from "./components/CronJobsLog";
import ProductAddLog from "./components/ProductAddLog";
import IndexingLog from "./components/IndexingLog";
import ArticleQueLoaderLog from "./components/ArticleQueLoaderLog";


const Logs = () => {

    const { plink } = useParams();
    const mp = new ModelProduct();
    // const [pData,setData] = useState(null);
    const [isLoaded,setLoaded] = useState(false);

    useEffect(() => {
        console.log('rendering dah');

    },[]);

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Logs</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    <Row>
                        <GeneralLog />
                    </Row>
                </Col>
                <Col xs={12} sm={6}>
                    <Row>
                        <DefaultErrorLog/>
                    </Row>
                </Col>
                <Col xs={12} sm={6}>
                    <Row>
                        <WriterLog/>
                    </Row>
                </Col>
                <Col xs={12} sm={6}>
                    <Row>
                        <CronJobsLog/>
                    </Row>
                </Col>
                <Col xs={12} sm={6}>
                    <Row>
                        <ProductAddLog/>
                    </Row>
                </Col>
                <Col xs={12} sm={6}>
                    <Row>
                        <IndexingLog/>
                    </Row>
                </Col>
                <Col xs={12} sm={6}>
                    <Row>
                        <ArticleQueLoaderLog/>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Logs;