import Http from '../../containers/App/Http';
import store from '../../containers/App/store'
import {
    setListData,
    setListProducts,
    setListImages
} from "../../redux/actions/Lists/listActions";

export default class Log{

    getLog(formData){
        // FETCH THE VENUE INFO
        return Http.post(process.env.REACT_APP_MAIN_APP_API_URL + "/api/v1/getLog?apiKey="+process.env.REACT_APP_MAIN_APP_API_KEY,formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });

    };

    getDefaultErrorLog(formData){
        // FETCH THE VENUE INFO
        return Http.post(process.env.REACT_APP_MAIN_APP_API_URL + "/api/v1/getDefaultErrorLog?apiKey="+process.env.REACT_APP_MAIN_APP_API_KEY,formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });

    };

}