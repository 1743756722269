import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
    Col,
    Row,
    Alert,
    Button,
    ButtonToolbar,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Card,
    CardBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIcon from "mdi-react/LoadingIcon";
import ProductsTable from "../../../Home/components/ProductsWidget/components/ProductsTable";
import Panel from "../../../../shared/components/Panel";
import Log from "../../../../Models/Logs/Log";

const WriterLog = ({ dispatch, rtl }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [log, setLog] = useState(null);
    const [logType, setLogType] = useState('ALL'); // State variable for log type
    const [numOfLines, setNumOfLines] = useState('50'); // State variable for number of lines
    const [showDate, setShowDate] = useState(false); // State variable for show date checkbox
    const logRef = useRef(null); // Create a ref for the log viewer
    const lm = new Log();

    useEffect(() => {
        fetchData();

        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, [logType, numOfLines, showDate]); // useEffect depends on logType, numOfLines, and showDate

    useEffect(() => {
        // Scroll to the bottom of the log viewer when log is updated and isLoaded is true
        if (isLoaded && logRef.current) {
            logRef.current.scrollTop = logRef.current.scrollHeight;
        }
    }, [log, isLoaded]); // Depend on log and isLoaded

    const fetchData = () => {
        const formdata = new FormData();
        formdata.append('logName', 'writer');
        formdata.append('numberOfLines', numOfLines);
        formdata.append('showDate', showDate); // Add showDate to form data

        if (logType !== 'ALL') {
            formdata.append('type', logType);
        }

        return lm.getLog(formdata).then(async response => {
            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            setLog(response.data.data);
            setIsLoaded(true);
            return true;
        });
    };

    return (
        <Panel xl={12} lg={12} md={12} title={'Writer Log'} refreshFunction={fetchData}>
            {!isLoaded && <div className="panel__refresh"><LoadingIcon /></div>}
            {isLoaded && (
                <>
                    <Row className="pb-2 pt-2">
                        <Col xs={4}>
                            <select value={logType} onChange={(e) => setLogType(e.target.value)}>
                                <option value="ALL">All</option>
                                <option value="NOTICE">Notice</option>
                                <option value="ERROR">Error</option>
                                <option value="WARNING">Warning</option>
                                <option value="EXCEPTION">Exception</option>
                            </select>
                        </Col>
                        <Col xs={4}>
                            <select value={numOfLines} onChange={(e) => setNumOfLines(e.target.value)}>
                                <option value="50">50 Lines</option>
                                <option value="100">100 Lines</option>
                                <option value="200">200 Lines</option>
                                <option value="300">300 Lines</option>
                            </select>
                        </Col>
                        <Col xs={4}>
                            <label className="checkbox-btn " htmlFor="showDate">
                                <input
                                    id="showDate"
                                    checked={showDate}
                                    type="checkbox"
                                    onChange={(e) => setShowDate(e.target.checked)}
                                />
                                <span className="checkbox-btn__label">Show Date</span>
                            </label>
                        </Col>
                    </Row>
                    <pre className="logViewer p-2" ref={logRef}>{log}</pre> {/* Attach the ref to the log viewer */}
                </>
            )}
        </Panel>
    );
};

export default connect(state => ({
    rtl: state.rtl
}))(WriterLog);