import React,{ useState,useEffect } from 'react';
import {Card, CardBody, CardTitle, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';
import ModelProduct from '../../Models/Products';
import ProductsWidget from "./components/ProductsWidget";
import FeaturedCategories from "../../shared/components/FeaturedCategories";
import ProductsModified from "./components/ProductsModified";
import JobsExecuted from "../../shared/components/CronJobs/JobsExecuted";
import ProductsCreated from "./components/ProductsCreated";
import ModalAddProduct from "../../shared/components/ModalAddProduct";

const Home = () => {

    const { plink } = useParams();
    const mp = new ModelProduct();
    // const [pData,setData] = useState(null);
    const [isLoaded,setLoaded] = useState(false);

    useEffect(() => {

    },[]);

    return (
        <Container className="Home">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Home</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={3}>
                    <Card>
                        <CardBody>
                            <ProductsModified/>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12} sm={3}>
                    <Card>
                        <CardBody>
                            <ProductsCreated/>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12} sm={3}>
                    <Card>
                        <CardBody>
                            <JobsExecuted/>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
            <Row>
                <FeaturedCategories/>
            </Row>
            <Row>
                <Col className="pb-3" xs={12} sm={3}>
                    <ModalAddProduct  />
                </Col>
            </Row>
            <Row>
                <ProductsWidget  />
            </Row>
        </Container>
    );
}

export default Home;