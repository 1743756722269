import React,{ useEffect,useState } from "react";
import { Redirect, Route } from "react-router-dom";
import Authentication from '../../Models/Authentication';
import { useHistory,useLocation } from "react-router-dom";
import {setUser} from "../../redux/actions/userActions";
import {connect} from "react-redux";

/*
    Role advanced ids is a array of the allowed role types for the route.
 */
const ProtectedRoute = ({dispatch, user, roleAdvancedIds , component: Component, ...restOfProps }) => {

    const history = useHistory();
    const location = useLocation();
    const [isLoaded, setLoaded] = useState(false);
    const am = new Authentication();

    let isAuthenticated = localStorage.getItem("isAuthenticated");

    useEffect(async ()=>{

            // console.log("this run use effect",location);

            await am.authCheck().then(() => {
                if (typeof localStorage.user === "undefined")
                    return;
                // SET THE USER DATA FROM LOCALSTORAGE
                dispatch(setUser(JSON.parse(localStorage.user)));
            });

        // history.listen(async (location)=>{
        //     await am.authCheck();
        // })
    },[]);

    // const firstLoad = async () => {
    //     if(!isLoaded){
    //     setLoaded(true);
    //     await Authentication.authCheck();
    // }};
    // firstLoad();

    // if(localStorage.getItem('expire_at') !== null){
    //     let eat = new Date(localStorage.getItem('expire_at') * 1000);
    //     let now = new Date();
    //
    //     if(now.getTime() > eat.getTime()){
    //         // NOW IS NEWER THAN THE EAT. TOKEN EXPIRED
    //
    //         // ATTEMPT TO USE THE REFRESH TOKEN
    //
    //
    //         // UPDATE THE LOCAL STORAGE TO isAuthenticated = "N"
    //         AuthService.logout().then(response => {
    //             isAuthenticated = "N";
    //             return history.push("/login");
    //
    //         });
    //     }
    // }

    // console.log("this", isAuthenticated);

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                (isAuthenticated === "Y") ? <Component {...props} /> : <Redirect to="/login" />
            }
        />
    );
};

export default connect(state => ({
    user : state.user.main
}))(ProtectedRoute);