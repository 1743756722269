import React,{useEffect,useState} from 'react';
import {
    Col,
    Card,
    CardImgOverlay,
    CardImg,
    Button
} from "reactstrap";
import Panel from "../../../../../../shared/components/Panel";
import {connect} from "react-redux";
import ModelProduct from '../../../../../../Models/Product';

const Images = ({productData}) => {

    const mv = new ModelProduct();
    const [images,setImages] = useState(false);

    useEffect(()=>{
        if(productData.hasOwnProperty('p_images')){
            if(productData.p_images === null || productData.p_images.trim().length === 0)
                return;
            setImages(productData.p_images.split(","));
        }
    },[productData]);

    const setImageAsFeatured = (e) => {
        e.preventDefault();
        let imageToFeatured = e.target.value;
        let formData= new FormData();
        formData.append('filename', imageToFeatured);
        formData.append('productId', productData.p_id);
        mv.setFeaturedImage(formData);
    };

    return (
        images!==false &&
        <div className={'row'}>
            {images.map((image, index) => (
                <Col md="3" className="my-2" key={index} >
                    <Card className="venueImageCard image-card">
                        <CardImg src={process.env.REACT_APP_BASE_API_URL +"/assets/images/"+ image.trim()} onError={(e) => e.target.src = (process.env.NODE_ENV === 'production') ? 'https://via.placeholder.com/300' : ""}/>
                        <CardImgOverlay className={'venueImageCardOverlay'} >
                            {/*<CardTitle className='text'>Weather</CardTitle>*/}
                            <Button className={'btn-primary btn'} onClick={setImageAsFeatured} value={image.trim()}>Set Featured</Button>
                            {/*<CardText className='text'>Summer</CardText>*/}
                        </CardImgOverlay>
                    </Card>
                </Col>
            ))}
        </div>
    );
};

export default connect(state => ({
    productData:state.product.productData
}))(Images);

