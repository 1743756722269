import React, {useEffect, useState} from 'react';
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ModelProducts from '../../../../Models/Products';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import {connect} from "react-redux";
import Panel from "../../../../shared/components/Panel";
import {showNotification} from "../../../../shared/components/Notification";
import LoadingIcon from 'mdi-react/LoadingIcon';

const ProductsModified = ({ dispatch, loadedProducts,rtl,theme }) => {
    const { productId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [modifiedProducts, setModifiedProducts] = useState([]);
    const [productsModifiedDateSelect, setProductsModifiedDateSelect] = useState(1); // the initial state should be 1
    const vm = new ModelProducts();

    useEffect(() => {
        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(startDate.getDate() - productsModifiedDateSelect); // calculate start date based on selected days
        const endDate = today.toISOString().split('T')[0];
        const formattedStartDate = startDate.toISOString().split('T')[0];

        const formData = new FormData();
        formData.append('startDate', formattedStartDate);
        formData.append('endDate', endDate);

        return vm.getModifiedByDate(formData).then(async response => {

            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            setModifiedProducts(response.data.data);
            setIsLoaded(true);
            return true;
        });
    },[productsModifiedDateSelect]);

    const handleSelectChange = (e) => {
        setIsLoaded(false); // set loading to false when changing the date range
        setProductsModifiedDateSelect(parseInt(e.target.value)); // update the state when select value is changed
    };

    return (
        <>
            <div className="card__title">
                <h5 className="bold-text">Modified Products</h5>
            </div>
            <select
                id={"productsModifiedDateSelect"}
                value={productsModifiedDateSelect} // controlled component
                onChange={handleSelectChange} // handle select change
            >
                <option value="1">24 Hours</option>
                <option value="7">7 Days</option>
                <option value="14">14 Days</option>
            </select>
            {!isLoaded &&
            <div className="panel__refresh"><LoadingIcon/></div>
            }
            {isLoaded &&
            <h3>{modifiedProducts.length}</h3> // Display modified products data
            }
        </>

    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    loadedProducts:state.products.loadedProducts,
    // showVenueMenu:state.venue.showVenueMenu
}))(ProductsModified);