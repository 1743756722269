import React from 'react';
import {Col, Card, CardBody, CardTitle, Row} from 'reactstrap';
import Carousel from '@/shared/components/carousel/CarouselMultiply';
import {connect} from "react-redux";

const ListImagesV2 = ({dispatch,listImages}) => {



        return (
            <div className="d-block d-sm-none">
                <div className=" scrolling-wrapper-flexbox">


                    {listImages.map((file, index) => (
                        <div key={index} className="bsCardV6 col-9 col-sm-5 col-md-4">
                            <div className="card mb-2 animate__animated animate__fadeInUp"
                                 style={{borderRadius:'10px',cursor:'pointer'}}>
                                {/*<div className="bsCardV6Overlay"></div>*/}
                                {/*<div style={{position: 'absolute'}}>*/}
                                {/*    <h4 className="bsCardV6Title">{file.i_name}</h4>*/}
                                {/*</div>*/}

                                <img src={process.env.REACT_APP_BASE_API_URL +"/assets/list_images/"+file.i_filename} className="img-fluid"
                                     style={{borderRadius: '10px', objectFit: 'cover', objectPosition: 'center center'}}
                                />
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            );
};

export default connect(state => ({
  rtl: state.rtl,
  theme: state.theme,
  listImages:state.list.listImages,
  user : state.user.main
}))(ListImagesV2);
