import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelProducts from '../../../Models/Products';
import { Alert, Button,Row, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalAddProduct = ({dispatch, data, features, specifications, rtl}) => {
    const [modal, setModal] = useState(false);
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [addProductType,setAddProductType] = useState(null);
    const mu = new ModelProducts();

    useEffect(async () => {

    },[]);

    const toggle = async () => {
        setError(false);
        setSuccess(false);
        setAddProductType(null);
        await setModal(prevState => !prevState);
    };

    const handleProductSource= (e)=>{
        setAddProductType(e.target.value)
    };

    const handleSubmit= () =>{
        document.querySelector("#ModalAddProductAddForm button[type=submit]").click();
    };

    const continueSubmission=(form)=>{
        form.preventDefault();

        let formData = new FormData(document.getElementById('ModalAddProductAddForm'));

        mu.addProduct(formData).then(response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setSuccess(false);
                return false;
            }

            setError(false);
            setSuccess(response.data.msg);
            // mu.getProductData(data.p_id).then(response=>{
            //     if (!response) {
            //         alert('failed to load users');
            //         return false;
            //     }
            //     return true;
            // });
        });
    };

    return (
        <div>
            <Button color={"info"} className={"btn-sm btn-block mb-0 mt-2"} onClick={toggle}>Add Product <FontAwesomeIcon icon="user"/></Button>

            <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="lg">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">Add Product</h4>
                </div>

                <div className="modal__body">
                        <form id="ModalAddProductAddForm" className={"form--horizontal form"} onSubmit={continueSubmission}>
                                <button type="submit" style={{'display':'none'}}>Submit</button>

                                <Alert color="danger" isOpen={!!errorMsg}>
                                    {errorMsg}
                                </Alert>
                                <Alert color="success" isOpen={!!successMsg}>
                                    {successMsg}
                                </Alert>

                                <div className="form__form-group mt-3">
                                    <div className="col-12">
                                        <select className="form-control form-control-sm col-12" name="s_advanced_id" onChange={handleProductSource}>
                                            <option value="">Select a source</option>
                                            <option value="AMZN">Amazon</option>
                                            <option value="BUCD">Bullish Coder</option>
                                        </select>
                                        <hr/>
                                    </div>
                                    {addProductType === "AMZN" && // AMAZON
                                        <div className="col-12">
                                            <label>Product ASIN</label>
                                            <input type="text" className="form-control form-control-sm" name="paq_identifier" required/>
                                            <small>How to find product ASIN</small>
                                        </div>
                                    }
                                    {addProductType === "BUCD" && // BC
                                        <h3>Manual Entry of product. Click add and you will be routed to the product page</h3>
                                    }

                                    <div className="col-sm-12 text-right">
                                        <Row>
                                            <div className="col-sm-12 text-right">
                                                <hr/>
                                                <button className="btn btn-primary btn-sm btn-block" type="button" onClick={handleSubmit}>Add</button>
                                            </div>
                                        </Row>
                                    </div>
                                </div>

                        </form>
                </div>
            </Modal>
        </div>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    data:state.product.productData,
    features:state.product.features,
    specifications:state.product.specifications,
    // users:state.users.users,
}))(ModalAddProduct);