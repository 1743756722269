import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Col, Container, Row, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import ModelJobs from '../../../Models/CronJobs/CronJobs';

const CreateCronJob = () => {
    const { plink } = useParams();
    const mjs = new ModelJobs();
    const [formData, setFormData] = useState({
        cj_name: '',
        cj_parameters_json: '',
        cj_action: '',
        cj_frequency: '',
        cj_active_flag: false,
    });
    const [responseMessage, setResponseMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const submit = async (e) => {
        e.preventDefault();
        const response = await mjs.createJob(formData);
        if (response.data.status === 'failed') {
            setResponseMessage('Job creation failed: ' + response.data.msg);
        } else {
            setResponseMessage('Job created successfully.');
        }
    };

    return (
        <Container className="Home">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Create Cron Job</h3>
                    {responseMessage && <div>{responseMessage}</div>}
                </Col>
            </Row>
            <Form onSubmit={submit}>
                <FormGroup>
                    <Label for="cj_name">Job Name</Label>
                    <Input type="text" name="cj_name" id="cj_name" placeholder="Enter Job Name" onChange={handleInputChange} />
                </FormGroup>
                <FormGroup>
                    <Label for="cj_parameters_json">Parameters JSON</Label>
                    <Input type="textarea" name="cj_parameters_json" id="cj_parameters_json" value={formData.cj_parameters_json} onChange={handleInputChange} />
                    <Button type="button" onClick={() => setFormData({
                        ...formData,
                        cj_parameters_json: JSON.stringify({
                            "type": "review",
                            "iteration": {
                                "type": "products",
                                "query": "select '14' as ratingRange, '14' as priceRange, p.p_id from products p join images i on i.p_id = p.p_id where p.p_active = 'Y' and p.p_rating is not null and p.p_featured_img_id is not null group by p.p_id order by p.p_id"
                            },
                            "datasetId": "4",
                            "generatorClass": "ProductReviewV2",
                            "wordpressSettings": {
                                "url": "https://kvmswitchreviews.com/",
                                "username": "bmp_admin",
                                "password": "omwU ohK9 pJkI qB1A wwzG UgfK",
                                "siteName": "kvmswitchreviews"
                            }
                        }, null, 2)
                    })}>Load Default JSON</Button>
                </FormGroup>
                <FormGroup>
                    <Label for="cj_action">Action</Label>
                    <Input type="select" name="cj_action" id="cj_action" onChange={handleInputChange}>
                        <option value="">Select Action</option>
                        {/* Add options here */}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="cj_active_flag">Active Flag</Label>
                    <Input type="select" name="cj_active_flag" id="cj_active_flag" onChange={handleInputChange}>
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </Input>
                </FormGroup>
                <Button type="submit">Create Job</Button>
            </Form>

        </Container>
    );
}

export default CreateCronJob;