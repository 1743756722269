import React,{ useState,useEffect } from 'react';
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {useHistory} from "react-router-dom";
import ModelProduct from '../../Models/Products';
import ModelLists from '../../Models/Lists/Lists';
import ListsTable from "../../shared/components/ListsTable";
import {connect} from "react-redux";

const Lists = () => {

    const mp = new ModelProduct();
    const ml = new ModelLists();
    // const [pData,setData] = useState(null);
    const [isLoaded,setLoaded] = useState(false);
    const [data,setData] = useState(null);

    useEffect(() => {
        ml.getLists().then((res)=>{
            if (res.data.status === 'failed')
                return;
            setData(res.data.data);
        });
    },[]);

    const history = useHistory();

    const redirect = (link) =>{
        history.push(link);
    };

    const columns =[
        {
            width: 50,
            Header: "Image",
            Cell: ({ cell }) => {
                return (
                    <>
                        {cell.row.original.p_featured_image===null &&
                        <img style={{borderRadius:'10px', maxWidth:'100px'}} src="https://picsum.photos/300/200" alt="Featured Image" />
                        }
                        {cell.row.original.p_featured_image!==null &&
                        <img style={{borderRadius:'10px', maxWidth:'100px'}} src={process.env.REACT_APP_BASE_API_URL +"/assets/images/"+cell.row.original.p_featured_image} alt="" />
                        }

                    </>
                )
            }
        },{
            width: 300,
            Header: "Name",
            Cell: ({ cell }) => {
                return (
                    <p style={{cursor:'pointer'}} className={ "m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); redirect('/l/'+cell.row.original.l_permalink)}}>
                        {cell.row.original.l_name}
                    </p>
                )
            }
        },
        {
            Header: 'Name',
            accessor: 'l_name',
            width: 150
        },
        {
            Header: 'Created',
            accessor: 'l_created',
            width: 150
        },
        {
            Header: 'Items',
            accessor: 'number_of_items',
            width: 150
        },
        {
            Header: '$ Trend',
            accessor: 'price_trend',
            width: 150
        }
    ];

    return (
        <Container className="Home">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Lists</h3>
                </Col>
            </Row>
            <Row>
                <Card>
                    <CardBody>
                        <ListsTable data={data} allowSearch={true} columnsStructure={columns}  />
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme
}))(Lists);