import React,{ useEffect,useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {connect} from "react-redux";
import ModelCategories from '../../../Models/Categories';
import { faUserSlash,faPencilAlt,faEye,faTimes } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../shared/components/Notification";
import { Link } from 'react-router-dom';

const FeaturedCategories = ({ dispatch,theme,rtl}) => {

    const history = useHistory();
    const [isLoaded,setLoaded] = useState(false);
    const [categories,setCategories] = useState(false);
    const cm = new ModelCategories();

    const redirect = (link) =>{
        history.push(link);
    };

    useEffect(() =>{
        let formData = new FormData();
        formData.append("limit","6");
        formData.append("hasPermalink","Y");
        // showNotification(theme,'success',rtl,'Update Product',"hello");
        cm.getAllCategories(formData).then(async (response)=>{
            await setCategories(response.data.data);
            setLoaded(true);
        });
    },[]);

    return (
        isLoaded &&
            <>
                {categories!==false && categories.map((c,i)=>(
                    <Card className="col-4" key={i}>
                        <CardBody className="text-center">
                            <Link to={"/c/"+c.pc_permalink}><h4>{c.pc_name}</h4></Link>
                        </CardBody>
                    </Card>
                ))}
            </>

    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme
}))(FeaturedCategories);
