import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {
    Col,
    Row,
    Alert,
    Button,
    ButtonToolbar,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Card,
    CardBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductSpecsTable from "./components/ProductSpecsTable";
import {faStar, faStarHalf} from "@fortawesome/fontawesome-free-solid";
import {faStar as emptyStar} from "@fortawesome/free-regular-svg-icons";
import {Link} from "react-router-dom";

const ProductSpecifications = ({dispatch, data, features, specifications, rtl}) => {

    return (

            <Card style={{height:"auto"}}>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">Product Specifications</h5>
                    </div>
                    <Row>
                        <Col xs={'12'} sm={'12'}>
                            <ul className="p-0" style={{listStyle:"none"}}>
                                {specifications!==false && specifications.map((specification,i)=>(
                                    <li key={i} className="pb-1 pt-2" style={{borderBottom: "1px solid #33333a"}}>
                                        <h5 style={{fontWeight: "700"}}>{specification.ps_name}</h5>
                                        <h5>{specification.ps_value}</h5>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    data:state.product.productData,
    features:state.product.features,
    specifications:state.product.specifications,
    // users:state.users.users,
}))(ProductSpecifications);