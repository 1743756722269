/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';
import Authentication from "../../Models/Authentication";
import { useHistory } from "react-router-dom";
import {
    setUser
} from "../../redux/actions/userActions";
import {connect} from "react-redux";

const LogIn = ({dispatch,user}) => {

    const history = useHistory();
    const am = new Authentication();

    const handleLogin = (loginCredentials) => {
        // this.setState({
        //     message: "",
        //     loading: true
        // });

        am.login(loginCredentials.username, loginCredentials.password).then(
            (response) => {
                if(response===false)
                    return;

                // SET USER AND ROLES
                dispatch(setUser(response.data.data.user));

                history.push("/");
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );

    };


    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">Product Analytics <span className="account__logo">LOGIN</span></h3>
                    <h4 className="account__subhead subhead">Start your business easily</h4>
                </div>
                <LogInForm onSubmit={handleLogin}/>
            </div>
        </div>
      </div>
    );

};

export default connect(state => ({
    user : state.user.main
}))(LogIn);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles
