import React, {useEffect, useState} from 'react';
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ModelProducts from '../../../../Models/Products';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import {connect} from "react-redux";
import Panel from "../../../../shared/components/Panel";
import {showNotification} from "../../../../shared/components/Notification";
import LoadingIcon from 'mdi-react/LoadingIcon';

const ProductsCreated = ({ dispatch, rtl,theme }) => {
    const { productId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [productsCreated, setProductsCreated] = useState([]);
    const [productsCreatedDateSelect, setProductsCreatedDateSelect] = useState(1); // the initial state should be 1
    const vm = new ModelProducts();

    useEffect(() => {
        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(startDate.getDate() - productsCreatedDateSelect); // calculate start date based on selected days
        const endDate = today.toISOString().split('T')[0];
        const formattedStartDate = startDate.toISOString().split('T')[0];

        return vm.getAllProducts(null,null,null, formattedStartDate, endDate).then(async response => {

            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            setProductsCreated(response.data.data);
            setIsLoaded(true);
            return true;
        });
    },[productsCreatedDateSelect]);

    const handleSelectChange = (e) => {
        setIsLoaded(false); // set loading to false when changing the date range
        setProductsCreatedDateSelect(parseInt(e.target.value)); // update the state when select value is changed
    };

    return (
        <>
            <div className="card__title">
                <h5 className="bold-text">Products Created</h5>
            </div>
            <select
                id={"productsCreatedDateSelect"}
                value={productsCreatedDateSelect} // controlled component
                onChange={handleSelectChange} // handle select change
            >
                <option value="1">24 Hours</option>
                <option value="7">7 Days</option>
                <option value="14">14 Days</option>
            </select>
            {!isLoaded &&
            <div className="panel__refresh"><LoadingIcon/></div>
            }
            {isLoaded &&
            <h3>{productsCreated.length}</h3> // Display modified products data
            }
        </>

    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme
}))(ProductsCreated);