import Http from '../../containers/App/Http';
import store from '../../containers/App/store'
import {
    setListsByUser,
    setFeaturedLists,
    setLatestLists
} from "../../redux/actions/Lists/listsActions";
import Authentication from "../Authentication";

export default class Lists{

    auth = new Authentication();

    createNewList(formData){

        return Http.post("/s_ls/createList",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setListsByUser(response.data.data));
            return response;

        });

    }

    getLists(){

        // FETCH THE VENUE INFO
        return Http.post("/ls/getLists").then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });

    };

    getAllListsByUser(){

        // FETCH THE VENUE INFO
        if((this.auth.isAuthenticated())){

            return Http.post("/s_ls/getAllListsByUser").then(async response => {
                if (response.data.status === 'failed')
                    return response;

                await store.dispatch(setListsByUser(response.data.data));

                return response;
            });
        }else{
            return new Promise(resolve => resolve({
                data: {
                    status:false
                }
            }));
        }


    };

    getAllListsForListsPage(){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        // formData.append('productId',productId);
        // formData.append('priceHistoryRange',priceHistoryRange);
        // formData.append('ratingHistoryRange',ratingHistoryRange);
        if((this.auth.isAuthenticated())){
            return Http.post("/s_ls/getAllListsForListsPage",formData).then(async response => {
                if (response.data.status === 'failed')
                    return response;


                // item 0 is featured
                // item 1 is latest
                await store.dispatch(setFeaturedLists(response.data.data[0]));
                await store.dispatch(setLatestLists(response.data.data[1]));
                // item 2 is user
                if(typeof response.data.data[2] !== "undefined")
                    await store.dispatch(setListsByUser(response.data.data[2]));

                return response;
            });
        }else{
            return Http.post("/ls/getAllListsForListsPage",formData).then(async response => {
                if (response.data.status === 'failed')
                    return response;


                // item 0 is featured
                // item 1 is latest
                await store.dispatch(setFeaturedLists(response.data.data[0]));
                await store.dispatch(setLatestLists(response.data.data[1]));
                // item 2 is user
                if(typeof response.data.data[2] !== "undefined")
                    await store.dispatch(setListsByUser(response.data.data[2]));

                return response;
            });
        }


    };

    getFeaturedLists(){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        // formData.append('productId',productId);
        // formData.append('priceHistoryRange',priceHistoryRange);
        // formData.append('ratingHistoryRange',ratingHistoryRange);

        return Http.post("/ls/getFeaturedLists",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setFeaturedLists(response.data.data));

            return response;
        });
    };
}