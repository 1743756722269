import { handleActions } from 'redux-actions';
import {
    setListData,
    setListProducts,
    setListImages
} from '../../actions/Lists/listActions';

const defaultState = {
    listData: null,
    listProducts:null,
    listImages:null
};

export default handleActions(
  {
      [setListData](state, { payload }) {
          return { ...state, listData: payload };
      },[setListProducts](state, { payload }) {
          return { ...state, listProducts: payload };
      },[setListImages](state, { payload }) {
          return { ...state, listImages: payload };
      }
  },
  defaultState,
);
