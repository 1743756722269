import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {setProducts} from "../redux/actions/productsActions";


export default class Products{

    getAllProducts(categories, active, setInStore, startDate, endDate) {
        let formData = new FormData();
        if (typeof categories !== "undefined" && categories !== null) formData.append("categories", categories);
        if (typeof active !== "undefined" && active !== null) formData.append("active", active);

        // Adding conditions to append startDate and endDate if they are provided
        if (typeof startDate !== "undefined" && startDate !== null && typeof endDate !== "undefined" && endDate !== null) {
            formData.append("startDate", startDate);
            formData.append("endDate", endDate);
        }

        return Http.post("/ps/getAllProducts", formData).then(async response => {
            if (response.data.status === 'failed') return response;

            if (typeof setInStore === "undefined" || setInStore === true)
                await store.dispatch(setProducts(response.data.data));

            return response;
        });
    };

    addProduct(formData){

        return Http.post(process.env.REACT_APP_MAIN_APP_API_URL + "/api/v1/addProductToQue",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    getModifiedByDate(formData){
        return Http.post("/ps/getModifiedByDate",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    }


}