import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {
    Col,
    Row,
    Alert,
    Button,
    ButtonToolbar,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Card,
    CardBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIcon from "mdi-react/LoadingIcon";
import ProductsTable from "../../../Home/components/ProductsWidget/components/ProductsTable";
import Panel from "../../../../shared/components/Panel";
import Log from "../../../../Models/Logs/Log";
import DataTable from "../../../../shared/components/DataTable";
import Tooltip from "rc-tooltip";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";

const ArticleQueFromSources = ({ dispatch, rtl }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [log, setLog] = useState(null);
    const [logType, setLogType] = useState('ALL'); // State variable for log type
    const [numOfLines, setNumOfLines] = useState('50'); // State variable for number of lines
    const lm = new Log();

    const columns =[
        {
            width: 300,
            Header: "Edit",
            Cell: ({ cell }) => {
                // debugger
                return (
                    <>
                        <Tooltip placement="top" overlay={"View in New Tab"}>
                            <a
                                href={cell.row.original.po_link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button type="button" className="btn btn-info btn-sm m-0 mr-2 mt-1 pt-1 pb-1 pl-2 pr-1">
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </button>
                            </a>
                        </Tooltip>
                    </>
                )
            }
        },{
            width: 50,
            Header: "Status",
            accessor: "st_code"
        },{
            width: 50,
            Header: "ID",
            accessor: "aqs_id"
        },{
            Header: 'Link',
            accessor: 'aqs_link'
        },{
            Header: 'Modified',
            accessor: 'aqs_modified'
        }
    ];

    return (
        <Panel xl={12} lg={12} md={12} title={'Article Que From Sources'} initialCollapse={false}>

            <>
                <Row>
                    <Col xs={12}>
                        <DataTable columns={columns} endpoint={"qs/getArticleQueFromSourcesForDataTable"} title={"Article Que"}/>
                    </Col>
                </Row>
            </>

        </Panel>
    );
};

export default connect(state => ({
    rtl: state.rtl
}))(ArticleQueFromSources);