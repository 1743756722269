import React, {useEffect, useState} from 'react';
import { Col, Container, Row } from 'reactstrap';
import ModelProduct from '../../../../Models/Product';
import {connect} from "react-redux";
import Images from "./components/Images";
import ModalUploadImage from "./components/ModalUploadImage";
import {showNotification} from "../../../../shared/components/Notification";

const ProductImages = ({ dispatch, images,rtl,theme }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const vm = new ModelProduct();

    useEffect(() => {
        // vm.getVenueData(venueId).then(async response => {
        //     if (response.data.status === 'failed') {
        //         alert('failed');
        //         return false;
        //     }
        //     return true;
        // });
        //
        // vm.getVenueImages(venueId).then(response => {
        //     if (response.data.status === "failed") {
        //         showNotification(theme,'danger',rtl,'Get Images',response.data.msg);
        //         return false;
        //     }
        //     setIsLoaded(true);
        //     return true;
        // });

    },[]);

    return (

            <>
                <Row>
                    <Col md={3} className={'mb-4'}>
                        <ModalUploadImage />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Images/>
                    </Col>
                </Row>
            </>

    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    images:state.product.images,
}))(ProductImages);