import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {
    Col,
    Row,
    Alert,
    Button,
    ButtonToolbar,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Card,
    CardBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIcon from "mdi-react/LoadingIcon";
import ProductsTable from "../../../Home/components/ProductsWidget/components/ProductsTable";
import Panel from "../../../../shared/components/Panel";
import Log from "../../../../Models/Logs/Log";

const DefaultErrorLog = ({dispatch, rtl}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [log, setLog] = useState(null);
    const lm = new Log();

    useEffect(() => {
        // dispatch(showVenueMenu(true));
        // Set up an interval to call fetchData every 5 seconds
        const intervalId = setInterval(fetchData, 5000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    },[]);

    const fetchData=()=>{
        const formdata = new FormData();
        formdata.append('numberOfLines',"50");

        return lm.getDefaultErrorLog(formdata).then(async response => {

            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            setLog(response.data.data);
            setIsLoaded(true);
            return true;
        });
    };

    return (

        <Panel xl={12} lg={12} md={12} title={'Default Error Log'} refreshFunction={fetchData}>
            {!isLoaded &&
            <div className="panel__refresh"><LoadingIcon/></div>
            }
            {isLoaded &&
            <pre className="logViewer p-2">{log}</pre>
            }

        </Panel>
    );
};

export default connect(state => ({
    rtl: state.rtl
}))(DefaultErrorLog);