import React,{ useState,useEffect } from 'react';
import {Card, CardBody, CardTitle, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';
import ModelCronJob from '../../../Models/CronJobs/CronJob';
import JobsExecuted from "../../../shared/components/CronJobs/JobsExecuted";
import CronJobsTable from "../../../shared/components/CronJobs/CronJobsTable";
import CronJobTable from "../../../shared/components/CronJobs/CronJobTable";

const CronJob = () => {

    const { cjId } = useParams();
    const mp = new ModelCronJob();
    const [jobData,setJobData] = useState(null);
    const [isLoaded,setLoaded] = useState(false);

    useEffect(() => {
        let fd = new FormData();
        fd.append('cj_id',cjId);
        mp.getJobData(fd).then((res)=>{
           setJobData(res.data.data);
           setLoaded(true);
        });

    },[]);

    return (
        <Container className="Home">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Cron Job</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={3}>
                    <Card>
                        <CardBody>
                            <JobsExecuted/>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
            <Row>
                {isLoaded &&
                    <CronJobTable title={"History"} cj_id={jobData['cj_id']}/>
                }

            </Row>

        </Container>
    );
}

export default CronJob;