import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelProduct from '../../../../../../Models/Product';
import { Alert, Button, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalFeatures = ({dispatch, data, features, specifications, rtl}) => {
    const [modal, setModal] = useState(false);
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const mu = new ModelProduct();

    useEffect(async () => {

    },[]);

    const toggle = async () => {
        setError(false);
        setSuccess(false);
        await setModal(prevState => !prevState);
    };

    const handleSubmit= () =>{
        document.querySelector("#ModalFeaturesAddForm button[type=submit]").click();
    };

    const continueSubmission=(form)=>{
        form.preventDefault();

        let formData = new FormData(document.getElementById('ModalFeaturesAddForm'));
        formData.append('productId',data.p_id);

        mu.addFeature(formData).then(response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setSuccess(false);
                return false;
            }

            setError(false);
            setSuccess(response.data.msg);
            mu.getProductDataForEditor(data.p_id).then(response=>{
                if (!response) {
                    alert('failed to load users');
                    return false;
                }
                return true;
            });
        });
    };

    return (
        <div>
            <Button color={"info"} className={"btn-sm btn-block mb-0 mt-2"} onClick={toggle}>Add Feature <FontAwesomeIcon icon="user"/></Button>

            <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="lg">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">Add Feature</h4>
                </div>

                <div className="modal__body">
                        <form id="ModalFeaturesAddForm" className={"form--horizontal form"} onSubmit={continueSubmission}>
                                <button type="submit" style={{'display':'none'}}>Submit</button>

                                <Alert
                                    color="danger"
                                    isOpen={!!errorMsg}
                                >
                                    {errorMsg}
                                </Alert>
                                <Alert
                                    color="success"
                                    isOpen={!!successMsg}
                                >
                                    {successMsg}
                                </Alert>

                                <div className="form__form-group mt-3">
                                    <div className="col-sm-6">
                                        <textarea type="text" rows={'6'} className="form-control-sm" name="pf_description" required/>
                                        <small>Enter the members e-mail address</small>
                                    </div>
                                    <div className="col-sm-3">
                                        <select className="form-control-sm col-12" name="pf_type" >
                                            <option value="G">General</option>
                                            <option value="P">Positive</option>
                                            <option value="N">Negative</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-3">
                                        <button className="btn btn-primary btn-sm btn-block" type="button" onClick={handleSubmit}>Add</button>
                                    </div>
                                </div>

                        </form>
                </div>
            </Modal>
        </div>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    data:state.product.productData,
    features:state.product.features,
    specifications:state.product.specifications,
    // users:state.users.users,
}))(ModalFeatures);