import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {
    Col,
    Row,
    Alert,
    Button,
    ButtonToolbar,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Card,
    CardBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CategoriesTable from "./components/CategoriesTable";

const ProductReviews = ({dispatch, data, features, specifications, rtl}) => {

    return (
        <Card>
            <CardBody>
                <div className="card__title">
                    <h5 className="bold-text">Product Reviews</h5>
                </div>
                <Col xs={'12'} sm={'12'}>
                    <CategoriesTable/>
                </Col>
            </CardBody>
        </Card>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    data:state.product.productData,
    features:state.product.features,
    specifications:state.product.specifications,
    // users:state.users.users,
}))(ProductReviews);