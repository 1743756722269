import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {
    setProduct,
    setFeatures,
    setSpecifications,
    setPriceTrackerData,
    setRatingTrackerData,
    setLinks,
    setCategories,
    setImages,
    setPriceExtended,
    setRatingExtended,
    setCategoriesExtended
} from "../redux/actions/productActions";

export default class ArticleWriter{

    getPrompts(formData){
        if(typeof formData === "undefined")
            formData = new FormData();

        return Http.post("/ag/getPrompts",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    getDestinations(formData){
        if(typeof formData === "undefined")
            formData = new FormData();

        return Http.post("/ag/getDestinations",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    generate(formData){
        if(typeof formData === "undefined")
            formData = new FormData();

        return Http.post("/ag/generate",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

}