import React,{ useState,useEffect } from 'react';
import {Card, CardBody, CardTitle, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';
import ModelProduct from '../../../Models/Products';
import FeaturedCategories from "../../../shared/components/FeaturedCategories";
import JobsExecuted from "../../../shared/components/CronJobs/JobsExecuted";
import CronJobsTable from "../../../shared/components/CronJobs/CronJobsTable";

const CronJobs = () => {

    const { plink } = useParams();
    const mp = new ModelProduct();
    // const [pData,setData] = useState(null);
    const [isLoaded,setLoaded] = useState(false);

    useEffect(() => {

    },[]);

    return (
        <Container className="Home">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Cron Jobs</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={3}>
                    <Card>
                        <CardBody>
                            <JobsExecuted/>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12} sm={3}>
                    <Card>
                        <CardBody>
                            <button className="btn btn-primary">Create Job</button>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
            <Row>
                <CronJobsTable title={"Cron Jobs"}/>
            </Row>

        </Container>
    );
}

export default CronJobs;