import Http from '../../containers/App/Http';
import store from '../../containers/App/store'
import {
    setListsByUser,
    setFeaturedLists,
    setLatestLists
} from "../../redux/actions/Lists/listsActions";
import Authentication from "../Authentication";

export default class Images{

    auth = new Authentication();

    uploadImage(formData){

        return Http.post(process.env.REACT_APP_MAIN_APP_API_URL+ "/api/v1/uploadImage?apiKey="+process.env.REACT_APP_MAIN_APP_API_KEY,formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            // await store.dispatch(setListsByUser(response.data.data));
            return response;

        });

    }

    deleteImage(formData){

        return Http.post("/s_ls/createList",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setListsByUser(response.data.data));
            return response;

        });

    }
}