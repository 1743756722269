import React,{ useEffect,useState } from 'react';
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import { faUserSlash } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../../../../shared/components/Notification";


const WhereToBuyTable = ({ dispatch, data, links ,theme,rtl}) => {

    const [isTableLoaded,setLoaded] = useState(false);

    useEffect(()=>{
        setLoaded(false);
        if(typeof links !== 'undefined' || links!==null){
            setLoaded(true);
        }
    },[links]);

    const goToBuy=()=>{

    };

    const columns =[
        {
            width: 300,
            Header: "Source",
            Cell: ({ cell }) => {
                 // debugger
                let src;
                if(cell.row.original.s_advanced_id==="AMZN")
                    src="/img/amazonLogoSmallForDarkTheme.png";
                return (
                    <a href={cell.row.original.pl_link} onClick={e => {e.preventDefault(); }}>
                        <img src={src} style={{maxWidth:"100px"}}/>
                    </a>
                )
            }
        },
        {
            Header: 'Price',
            accessor: 'pl_last_price'
        },{
            width: 300,
            Header: "Buy",
            Cell: ({ cell }) => {
                return (
                    <button className="btn btn-primary btn-sm mb-0" onClick={e => {e.preventDefault();window.open(cell.row.original.pl_link, '_blank');}}>Buy</button>
                )
            }
        },
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (
        isTableLoaded &&
            <DataReactTable reactTableData={ tableStructure } />
    );
};

export default connect(state => ({
    data:state.product.productData,
    links:state.product.links,
    rtl: state.rtl,
    theme: state.theme
}))(WhereToBuyTable);
