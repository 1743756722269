import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Alert, Button, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinksTable from "./components/LinksTable";

const ProductLinks = ({dispatch, data, features, specifications, rtl}) => {

    return (
            <Row>
                <Col xs={'12'} sm={'12'}>
                    <h2>Links</h2>
                </Col>
                <Col xs={'12'} sm={'12'}>
                    <LinksTable/>
                </Col>
            </Row>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    data:state.product.productData,
    features:state.product.features,
    specifications:state.product.specifications,
    // users:state.users.users,
}))(ProductLinks);