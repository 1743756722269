import React,{ useEffect,useState } from 'react';
import Panel from "../../Panel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelCronJobs from '../../../../Models/CronJobs/CronJobs';
import ModelCronJob from '../../../../Models/CronJobs/CronJob';
import {Link, useHistory} from "react-router-dom";
import {faExternalLinkAlt, faEye, faHotel, faPen, faPlay} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "rc-tooltip";
import DataTable from "../../DataTable";

const CronJobsTable = ({ dispatch, title, limit}) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const [submission,setSelectedSubmission] = useState(null);
    const cjsm = new ModelCronJobs();
    const cjm = new ModelCronJob();
    const history = useHistory();

    const redirect = (link) =>{
        history.push(link);
    };

    const executeJob = (cjId) =>{
        const formdata = new FormData();
        formdata.append('jobId',cjId);
        cjm.executeJob(formdata).then((response)=>{
            alert(response.data.msg);
        });
    };

    const columns =[
        {
            width: 300,
            Header: "Edit",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    <>
                        <Tooltip placement="top" overlay={"View/Edit"}>
                            <button className={ "btn btn-info btn-sm m-0 mr-2 mt-1 pt-1 pb-1 pl-2 pr-1"} onClick={() => redirect("/cj/"+cell.row.original.cj_id)}>
                                <FontAwesomeIcon icon={faEye}/>
                            </button>
                        </Tooltip>
                        <Tooltip placement="top" overlay={"View in New Tab"}>
                            <Link
                                to={"/cj/" + cell.row.original.cj_id}
                                target="_blank"
                            >
                                <button type={"button"} className={ "btn btn-info btn-sm m-0 mr-2 mt-1 pt-1 pb-1 pl-2 pr-1"}>
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </button>
                            </Link>
                        </Tooltip>
                        <Tooltip placement="top" overlay={"Execute Job"}>
                            <button className={ "btn btn-danger btn-sm m-0 mr-2 mt-1 pt-1 pb-1 pl-2 pr-1"} onClick={() => executeJob(cell.row.original.cj_id)}>
                                <FontAwesomeIcon icon={faPlay}/>
                            </button>
                        </Tooltip>
                    </>
                )
            }
        },{
            width: 300,
            Header: "Name",
            accessor: "cj_name"
        },{
            Header: 'ID',
            accessor: 'cj_id',
            width: 100
        },{
            Header: 'Action',
            accessor: 'cj_action',
            width: 150
        },{
            Header: 'Frequency',
            accessor: 'cj_frequency'
        },{
            Header: 'Active',
            accessor: 'cj_active_flag'
        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (

        <Panel xl={12} lg={12} md={12} title={title??"Submissions"} subhead="Table of Cron Jobs">
            <div>
                <DataTable columns={columns} endpoint={"/cjs/getCronJobsForDataTable"}/>
            </div>
        </Panel>

    );
};

export default connect(state => ({
}))(CronJobsTable);
