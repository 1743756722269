import React,{useState,useEffect} from "react";
import {connect} from "react-redux";
import {Card, CardBody, Col,Modal, Row} from 'reactstrap';
import ModelProduct from '../../../../Models/Product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/fontawesome-free-solid';

import {showNotification} from "../../../../shared/components/Notification";

const RatingPercentile = ({dispatch,productData,ratingExtended,theme,rtl}) => {

    const vm = new ModelProduct();
    const [modal, setModal] = useState(false);

    const toggle = async () => {
        await setModal(prevState => !prevState);
    };

    useEffect(() =>{

    },[productData]);

    return (
        <Col xs={6} md={4}>
            <Card>
                <CardBody style={{backgroundColor:"#20b2aa"}} className={"p-3"}>
                    <div className="card__title mb-1">
                        <h5 className="bold-text">Rating Percentile</h5>
                    </div>
                    <div>
                        <h4 className="dashboard__total-stat">{(ratingExtended.length!==0) ? ratingExtended[0].percentile+"%" : "N/A"}</h4>
                    </div>
                    <p><a href="javascript:void(0);" onClick={toggle} style={{color:"#fff"}}>What is this? <FontAwesomeIcon icon={faQuestionCircle} /></a></p>
                    <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="md">
                        <div className="modal__header">
                            <button
                                className="lnr lnr-cross modal__close-btn"
                                aria-label="modal__close-btn"
                                type="button"
                                onClick={toggle}
                            />
                            <h4 className="text-modal  modal__title">Rating Percentile</h4>
                        </div>
                        <div className="modal__body">

                        </div>

                    </Modal>
                </CardBody>
            </Card>
        </Col>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    productData:state.product.productData,
    ratingExtended:state.product.ratingExtended,
    // showVenueMenu:state.venue.showVenueMenu
}))(RatingPercentile);