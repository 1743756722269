import React,{ useEffect,useState } from 'react';
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import { faUserSlash } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../../../../shared/components/Notification";


const CategoriesTable = ({ dispatch, data, categories ,theme,rtl}) => {

    const [isTableLoaded,setLoaded] = useState(false);

    useEffect(()=>{
        setLoaded(false);
        if(typeof categories !== 'undefined' || categories!==null){
            setLoaded(true);
        }
    },[categories]);

    const columns =[
        // {
        //     width: 300,
        //     Header: "Options",
        //     Cell: ({ cell }) => {
        //          // debugger
        //         return (
        //             <a href={"/s/"+cell.row.original.qr_id} className={ "btn btn-danger btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); removeMemberAccess(cell.row.original)}}>
        //                 Remove <FontAwesomeIcon icon={faUserSlash}/>
        //             </a>
        //         )
        //     }
        // },
        {
            Header: 'ID',
            accessor: 'pc_id'
        },{
            Header: 'Category',
            accessor: 'pc_name',

        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (



                isTableLoaded &&
                    <DataReactTable reactTableData={ tableStructure } />



    );
};

export default connect(state => ({
    data:state.product.productData,
    categories:state.product.categories,
    rtl: state.rtl,
    theme: state.theme
}))(CategoriesTable);
