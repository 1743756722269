import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelProduct from '../../../../Models/Product';
import { Col, Row, Alert, Button, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PriceTrackerTable from "./components/PriceTrackerTable";
import RatingTrackerTable from "./components/RatingTrackerTable";

const ProductTrackers = ({dispatch, data, features, specifications,ratingTrackerData, priceTrackerData, rtl}) => {

    return (
        <>
            <Row>
                <Col xs={'12'} sm={'6'}>
                    <h3>Rating Tracker</h3>
                    <hr/>
                    <RatingTrackerTable/>
                </Col>
                <Col xs={'12'} sm={'6'}>
                    <h3>Price Tracker</h3>
                    <hr/>
                    <PriceTrackerTable/>
                </Col>
            </Row>

        </>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    data:state.product.productData,
    features:state.product.features,
    specifications:state.product.specifications,
    ratingTrackerData:state.product.ratingTrackerData,
    priceTrackerData:state.product.priceTrackerData,
}))(ProductTrackers);