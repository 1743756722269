import React,{ useEffect,useState } from 'react';
import Panel from "../../Panel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelCronJobs from '../../../../Models/CronJobs/CronJobs';
import ModelCronJob from '../../../../Models/CronJobs/CronJob';
import {Link, useHistory} from "react-router-dom";
import {faExternalLinkAlt, faEye, faHotel, faPen, faPlay} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "rc-tooltip";
import DataTable from "../../DataTable";

const PostsTable = ({ dispatch, title, limit}) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const [submission,setSelectedSubmission] = useState(null);
    const cjsm = new ModelCronJobs();
    const cjm = new ModelCronJob();
    const history = useHistory();

    const redirect = (link) =>{
        history.push(link);
    };

    const executeJob = (cjId) =>{
        const formdata = new FormData();
        formdata.append('jobId',cjId);
        cjm.executeJob(formdata).then((response)=>{
            alert(response.data.msg);
        });
    };

    const columns =[
        {
            width: 300,
            Header: "Edit",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    <>
                        <Tooltip placement="top" overlay={"View in New Tab"}>
                            <a
                                href={cell.row.original.po_link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button type="button" className="btn btn-info btn-sm m-0 mr-2 mt-1 pt-1 pb-1 pl-2 pr-1">
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </button>
                            </a>
                        </Tooltip>
                    </>
                )
            }
        },{
            width: 50,
            Header: "ID",
            accessor: "po_id"
        },{
            width: 50,
            Header: "Site",
            accessor: "wps_name"
        },{
            Header: 'Created',
            accessor: 'po_created'
        },{
            width: 50,
            Header: "User",
            accessor: "wps_username"
        },{
            Header: 'Category',
            accessor: 'po_category'
        },{
            Header: 'Title',
            accessor: 'po_title'
        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (

        <Panel xl={12} lg={12} md={12} title={title??"Submissions"} subhead="Table of Posts">
            <div>
                <DataTable columns={columns} endpoint={"/pts/getPostsForDatatable"}/>
            </div>
        </Panel>

    );
};

export default connect(state => ({
}))(PostsTable);
