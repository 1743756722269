import React, {useState} from 'react';
import {Col, Card, CardBody, CardTitle, Modal} from 'reactstrap';
import Carousel from '@/shared/components/carousel/CarouselMultiply';
import CarouselSingle from '@/shared/components/carousel/CarouselSingle';
import {connect} from "react-redux";
import logo from "../../../../shared/img/logo/logo_light.png";

const ListImages = ({dispatch,listImages,rtl,theme}) => {
    const [viewAddToList,setViewAddToList]= useState(false);

    const toggle=() => {
        setViewAddToList(prevState=>!prevState);
    };

    return (
        <Col md={12} lg={12} xl={12} className="d-none d-sm-block">
            <CarouselSingle>
                {listImages.map((item, index) => (
                    <div key={index}>
                        <img src={process.env.REACT_APP_BASE_API_URL +"/assets/list_images/"+item.i_filename} alt={`slide_${item.i_name}`}
                            onClick={()=>toggle()}
                            style={{borderRadius:"10px",maxHeight:"300px", objectFit: 'cover', objectPosition: 'center center'}}
                        />
                    </div>
                ))}
            </CarouselSingle>
            <Modal isOpen={viewAddToList} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} id="ModalLogin" modalClassName={`${rtl.direction}-support`} size="xl">
            <div className="modal__header">
            <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
            />


            </div>
            <div className="modal__body">
            <CarouselSingle>
            {listImages.map((item, index) => (
            <div key={index}>
                <img src={process.env.REACT_APP_BASE_API_URL +"/assets/list_images/"+item.i_filename} alt={`slide_${item.i_name}`}
                     style={{borderRadius:"10px", maxHeight:"80vh", objectFit: 'cover', objectPosition: 'center center'}}

                />
            </div>
            ))}


            </CarouselSingle>
            </div>
            </Modal>
        </Col>
    );
};

export default connect(state => ({
  rtl: state.rtl,
  theme: state.theme,
  listImages:state.list.listImages,
  user : state.user.main
}))(ListImages);
