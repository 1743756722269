import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import { showNotification } from "../../../shared/components/Notification";
import ModelWPSites from '../../../Models/Wordpress/WPSites';

const AddWPSite = ({ dispatch, theme, rtl }) => {
    const { plink } = useParams();
    const [isLoaded, setLoaded] = useState(false);
    const mWPSites = new ModelWPSites();

    useEffect(() => {

    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData(e.currentTarget);
        mWPSites.addSite(formData).then(async response => {
            if (response.data.status === 'failed'){
                showNotification(theme, 'danger', rtl, 'Add Site', response.data.msg);
                return;
            }

            showNotification(theme, 'success', rtl, 'Add Site', response.data.msg);
            return;
        });
    }

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Add Site</h3>
                </Col>
            </Row>
            <Row>
                <Card>
                    <CardBody>
                        <form className="form--horizontal form" onSubmit={handleSubmit}>
                            <div className="form__form-group row mt-1 mb-2">
                                <label htmlFor="wps_name" className="col-sm-2 form__form-group-label col-form-label">Name</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control-sm" id="wps_name" name="wps_name" />
                                </div>
                            </div>
                            <div className="form__form-group row mt-1 mb-2">
                                <label htmlFor="wps_username" className="col-sm-2 form__form-group-label col-form-label">Username</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control-sm" id="wps_username" name="wps_username" />
                                </div>
                            </div>
                            <div className="form__form-group row mt-1 mb-2">
                                <label htmlFor="wps_password" className="col-sm-2 form__form-group-label col-form-label">Password</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control-sm" id="wps_password" name="wps_password" />
                                </div>
                            </div>
                            <div className="form__form-group row mt-1 mb-2">
                                <label htmlFor="wps_sitename" className="col-sm-2 form__form-group-label col-form-label">Site Name</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control-sm" id="wps_sitename" name="wps_sitename" />
                                </div>
                            </div>
                            <div className="form__form-group row mt-1 mb-2">
                                <label htmlFor="wps_url" className="col-sm-2 form__form-group-label col-form-label">URL</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control-sm" id="wps_url" name="wps_url" />
                                </div>
                            </div>
                            <div className="form__form-group row mt-1 mb-2">
                                <label htmlFor="wps_max_daily_articles" className="col-sm-2 form__form-group-label col-form-label">Max Daily Articles</label>
                                <div className="col-sm-10">
                                    <input type="number" className="form-control-sm" id="wps_max_daily_articles" name="wps_max_daily_articles" />
                                </div>
                            </div>
                            <Row className={'form__form-group'}>
                                <Col xs={'12'} sm={'12'} className={'text-right'}>
                                    <hr/>
                                    <button className="btn btn-primary btn-sm" type="submit">Add</button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>

            </Row>

        </Container>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme
}))(AddWPSite);
