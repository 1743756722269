import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {
    Col,
    Row,
    Alert,
    Button,
    ButtonToolbar,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Card,
    CardBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIcon from "mdi-react/LoadingIcon";
import ProductsTable from "../../../Home/components/ProductsWidget/components/ProductsTable";
import Panel from "../../../../shared/components/Panel";
import Log from "../../../../Models/Logs/Log";

const ArticleQueLoaderLog = ({ dispatch, rtl }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [log, setLog] = useState(null);
    const [logType, setLogType] = useState('ALL'); // State variable for log type
    const [numOfLines, setNumOfLines] = useState('50'); // State variable for number of lines
    const lm = new Log();

    useEffect(() => {
        fetchData();

        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, [logType, numOfLines]); // useEffect now depends on logType and numOfLines

    const fetchData = () => {
        const formdata = new FormData();
        formdata.append('logName', 'articleQueLoader');
        formdata.append('numberOfLines', numOfLines);
        if (logType !== 'ALL') {
            formdata.append('type', logType);
        }

        return lm.getLog(formdata).then(async response => {
            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            setLog(response.data.data);
            setIsLoaded(true);
            return true;
        });
    };

    return (
        <Panel xl={12} lg={12} md={12} title={'Article Que Log'} refreshFunction={fetchData}>
            {!isLoaded &&
            <div className="panel__refresh"><LoadingIcon /></div>
            }
            {isLoaded &&
            <>
                <Row>
                    <Col xs={6}>
                        <select value={logType} onChange={(e) => setLogType(e.target.value)}>
                            <option value="ALL">All</option>
                            <option value="NOTICE">Notice</option>
                            <option value="ERROR">Error</option>
                            <option value="WARNING">Warning</option>
                            <option value="EXCEPTION">Exception</option>
                        </select>
                    </Col>
                    <Col xs={6}>
                        <select value={numOfLines} onChange={(e) => setNumOfLines(e.target.value)}>
                            <option value="50">50 Lines</option>
                            <option value="100">100 Lines</option>
                            <option value="200">200 Lines</option>
                            <option value="300">300 Lines</option>
                        </select>
                    </Col>
                </Row>
                <pre className="logViewer p-2">{log}</pre>
            </>
            }
        </Panel>
    );
};

export default connect(state => ({
    rtl: state.rtl
}))(ArticleQueLoaderLog);