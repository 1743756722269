import React,{ useEffect,useState } from 'react';
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import { faUserSlash } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../../../../shared/components/Notification";

const RatingTrackerTable = ({ dispatch, ratingTrackerData, theme,rtl}) => {

    const [isTableLoaded,setLoaded] = useState(false);

    useEffect(()=>{
        setLoaded(false);
        if(typeof ratingTrackerData !== 'undefined' || ratingTrackerData!==null)
            setLoaded(true);

    },[ratingTrackerData]);

    const columns =[
        // {
        //     width: 300,
        //     Header: "Options",
        //     Cell: ({ cell }) => {
        //          // debugger
        //         return (
        //             <a href={"/s/"+cell.row.original.qr_id} className={ "btn btn-danger btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); removeMemberAccess(cell.row.original)}}>
        //                 Remove <FontAwesomeIcon icon={faUserSlash}/>
        //             </a>
        //         )
        //     }
        // },
        {
            Header: 'Rating',
            accessor: 'rt_rating'
        },{
            Header: 'Date',
            accessor: 'rt_created',
            width: 150
        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (


            <div>
                {isTableLoaded &&
                    <DataReactTable reactTableData={ tableStructure } />
                }
            </div>

    );
};

export default connect(state => ({
    ratingTrackerData:state.product.ratingTrackerData,
    rtl: state.rtl,
    theme: state.theme
}))(RatingTrackerTable);
