import React, { useEffect,useState,useRef  } from 'react';
import { connect } from 'react-redux';
import { Button,Row,Col, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import Alert from "../Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Http from '../../../containers/App/Http';
import Cleave from "cleave.js/react";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import Authentication from "../../../Models/Authentication";
import ArticleWriter from "../../../Models/ArticleWriter";
import {Link} from "react-router-dom";

const ModalArticleWriter = ({dispatch, isOpen,toggle,rtl,theme, data}) => {
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [processing,setProcessing] = useState(false);
    const [prompts,setPrompts] = useState(false);
    const [prompt,setPrompt] = useState("");
    const [destinations,setDestinations] = useState(false);

    const am = new Authentication();
    const ag = new ArticleWriter();

    useEffect(() => {
        // GET DESTINATIONS
        ag.getDestinations().then((res)=>{
            setDestinations(res.data.data);
        });
        // GET PROMPTS
        ag.getPrompts().then((res)=>{
            setPrompts(res.data.data);
        });
    },[]);

    const promptSelection = (e) =>{
        setPrompt(prompts[e.target.value].ap_prompt + JSON.stringify(data));
    };

    const updatePrompt = (e) =>{
        setPrompt(e.target.value);
    };

    const handleGeneration = (e) => {
        e.preventDefault();
        let formData =new FormData(e.currentTarget);

        ag.generate(formData).then(
            (response) => {
                if(response===false)
                    return;

                debugger


            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );

    };

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} id="ModalArticleWriter" modalClassName={`${rtl.direction}-support`} size="xl">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />


                </div>
                <div className="modal__body">
                    <Form onSubmit={handleGeneration}>
                        <Row>
                            <Col xs={'12'}>
                                {errorMsg !==false &&
                                <Alert color="danger">
                                    {errorMsg}
                                </Alert>
                                }
                                {successMsg !== false &&
                                <Alert className="alert alert-success">
                                    <p>{successMsg}</p>
                                </Alert>
                                }
                            </Col>
                        </Row>

                        <div className="row">

                            <div className="col-4">
                                <h2>Select AI Prompt</h2>
                                <div id="mawSelectPromptContainer">
                                    {typeof prompts !=="undefined" && prompts !== false && prompts.map((prompt,i)=>(
                                        <div className="form-check" key={i}>
                                            <input name="promptSelector" value={i} type="radio" className="" onClick={promptSelection}/>
                                            <label>{prompt.ap_name}</label>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            <div className="col-4">
                                <h2>Destination</h2>
                                {typeof destinations !=="undefined" && destinations !== false && destinations.map((destination,i)=>(
                                    <div className="form-check" key={i}>
                                        <input name="destination" value={i} type="radio" className=""/>
                                        <label>{destination.url}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="col-4">
                                <h2>Prompt</h2>
                                <div className="row mb-4">
                                    <label className="col-sm-12 form__form-group-label col-form-label">Title</label>
                                    <div className=" col-sm-12">
                                        <input name="title" type="text" placeholder="title" className="form-control-sm form-control"/>
                                    </div>
                                </div>

                                <textarea rows="25" name="prompt" placeholder="prompt" className="form-control-sm form-control" value={prompt} onChange={updatePrompt}/>
                            </div>

                        </div>

                        <Row className="mt-4">
                            <Col xs={12}>
                                <button className="btn btn-primary account__btn account__btn--small" type="submit">Submit</button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
}))(ModalArticleWriter);