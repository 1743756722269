import React, { useEffect,useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarUnauthenticated = ({dispatch,user}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(()=>{

  },[user]);

  return (
    <div className="topbar__profile">
        <Link className="" to="/login">
            <p className="topbar__link-title"><span className={`topbar__link-icon lnr lnr-list`} /> Login</p>
        </Link>
        <Link className="" to="/register">
            <p className="topbar__link-title"><span className={`topbar__link-icon lnr lnr-list`} /> Register</p>
        </Link>


    </div>
  );
};

export default connect(state => ({
    user : state.user.main
}))(TopbarUnauthenticated);
