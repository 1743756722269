import React, {useEffect, useState} from 'react';
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ModelProduct from '../../Models/Product';
import { useParams } from 'react-router-dom';
import { setProduct } from '../../redux/actions/productActions';
import classnames from 'classnames';
import {connect} from "react-redux";
import Panel from "../../shared/components/Panel";
import {showNotification} from "../../shared/components/Notification";
import Main from "./components/Main";
import SpecsAndFeatures from "./components/SpecsAndFeatures";
import ProductTrackers from "./components/ProductTrackers";
import ProductLinks from "./components/ProductLinks";
import ProductImages from "./components/ProductImages";
import ProductArticles from "./components/ProductArticles";

const ProductEdit = ({ dispatch, productData,rtl,theme }) => {
    const { productId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const vm = new ModelProduct();
    const [activeTab, setActiveTab] = useState('1');


    // if(productData.vt_ids == null || productData.vt_ids.length === 0 || typeof productData.vt_ids === "undefined")
    //     return;

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        vm.getProductDataForEditor(productId).then(async response => {

            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            await setIsLoaded(true);
            return true;
        });
    },[productId]);

    return (

        isLoaded && (
            <Container className="dashboard">
                <Row>
                    <Panel xl={12} lg={12} md={12} refreshFunction="">
                        <div>
                            <div className="tabs tabs--bordered-top">
                                <div className="tabs__wrap">
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => toggle('1')}>Main</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => toggle('2')}>Specs and Features</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => toggle('3')}>Trackers</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => toggle('4')}>Product Links</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => toggle('5')}>Images</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '6' })} onClick={() => toggle('6')}>Articles</NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Main/>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Col xs="12">
                                                <Row>
                                                    <div className={"col-6"}>
                                                        {/*<InviteMembers/>*/}
                                                    </div>
                                                    <div className={"col-6"}>
                                                        {/*<AddMembers/>*/}
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <Col xs="12">
                                                        <SpecsAndFeatures/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Col xs="12">
                                                <ProductTrackers/>
                                            </Col>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <Col xs="12">
                                                <ProductLinks />
                                            </Col>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <Col xs="12">
                                                <ProductImages />
                                            </Col>
                                        </TabPane>
                                        <TabPane tabId="6">
                                            <Col xs="12">
                                                <ProductArticles />
                                            </Col>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    productData:state.product.productData,
    // showVenueMenu:state.venue.showVenueMenu
}))(ProductEdit);