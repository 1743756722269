import React,{ useState,useEffect } from 'react';
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {useParams} from 'react-router-dom';
import ModelProduct from '../../Models/Product';
import {connect} from "react-redux";
import Carousel from '../../shared/components/carousel/CarouselSingle';
import PriceHistory from "./components/PriceHistory";
import ProductSpecifications from "./components/ProductSpecifications";
import PositiveAndNegativeFeatures from "./components/PositiveAndNegativeFeatures";
import ProductReviews from "./components/ProductReviews";
import PricePercentile from "./components/PricePercentile";
import RatingPercentile from "./components/RatingPercentile";
import ProductsInCategory from "./components/ProductsInCategory";
import RatingHistory from "./components/RatingHistory";
import WhereToBuy from "./components/WhereToBuy";
import { Link } from 'react-router-dom';
import ProductBrief from "./components/ProductBrief";
import { Person } from "schema-dts";
import { JsonLd } from "react-schemaorg";


const Product = ({dispatch,data,features,specifications,priceHistoryRange,ratingHistoryRange}) => {

    const { plink } = useParams();
    const mp = new ModelProduct();
    const [imageLink,setImageLink] = useState(null);
    // const [pData,setData] = useState(null);
    const [isLoaded,setLoaded] = useState(false);
    let ORG_SCHEMA;
    let image;

    useEffect(() => {
        mp.getProductData(plink,priceHistoryRange,ratingHistoryRange).then(async response=>{
            if(response.data.data[0][0].p_images !==null && typeof response.data.data[0][0].p_images !== "undefined"){
                let splitImages = response.data.data[0][0].p_images.split(",");
                if(typeof splitImages[0]!=="undefined")
                    setImageLink(process.env.REACT_APP_BASE_API_URL +"/assets/images/"+splitImages[0].trim());
            }

            document.title =response.data.data[0][0].p_name;

            setLoaded(true);
        });
    },[]);

    return (
        isLoaded &&
            <Container className="dashboard">
                <script type='application/ld+json' dangerouslySetInnerHTML={ { __html: JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": data.p_name,
                        "image": [
                            imageLink
                        ],
                        "description": data.p_desc,
                        "sku": data.p_id,
                        "brand": {
                            "@type": "Brand",
                            "name": data.m_name
                        },
                        // "review": {
                        //     "@type": "Review",
                        //     "reviewRating": {
                        //         "@type": "Rating",
                        //         "ratingValue": "4",
                        //         "bestRating": "5"
                        //     },
                        //     "author": {
                        //         "@type": "Person",
                        //         "name": "Fred Benson"
                        //     }
                        // },
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": data.p_rating,
                            "ratingCount":50
                        },
                        "offers": {
                            "@type": "Offer",
                            "url": data.p_link,
                            "priceCurrency": "USD",
                            "price": data.p_last_price
                        }
                    })} } />

                {/*<script type='application/ld+json' dangerouslySetInnerHTML={ { __html: JSON.stringify({*/}
                        {/*"@context": "http://schema.org",*/}
                        {/*"@type": "Product",*/}
                        {/*"name": data.p_name,*/}
                        {/*"description": "Allround - An online learning experience through creative and co-curricular pursuits for kids aged 5-15. Learn western vocals, keyboard, chess & Spanish from experts.",*/}
                        {/*"url": process.env.REACT_APP_APPLICATION_URL + "/product/"+data.p_id,*/}

                    {/*})} } />*/}

                <Row>
                    <Col xs={12} md={4} className={'pt-2 pb-2'} id={'bannerV1'}>
                        <ProductBrief/>
                        <ProductSpecifications/>
                    </Col>
                    <Col xs={12} md={8}>
                        <h1 className="productTitle" style={{fontSize: "1.5rem"}}>{data.p_name}</h1>
                        <Row>
                            <PricePercentile/>
                            <RatingPercentile/>
                            <ProductsInCategory/>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <WhereToBuy/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <PriceHistory/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <PositiveAndNegativeFeatures/>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                            {/*<Col xs={12}>*/}
                                {/*<ProductSpecifications/>*/}
                            {/*</Col>*/}
                        {/*</Row>*/}
                        <Row>
                            <Col xs={12}>
                                <RatingHistory/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {/*<ProductReviews/>*/}
                            </Col>
                        </Row>
                    </Col>
                </Row>


                <Row>
                    <Col xs={12} md={12}>
                    <Card>
                        <CardBody>

                        </CardBody>
                    </Card>
                    </Col>
                </Row>
        </Container>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    data:state.product.productData,
    features:state.product.features,
    specifications:state.product.specifications,
    priceHistoryRange: state.theme.priceHistoryRange,
    ratingHistoryRange: state.theme.ratingHistoryRange
    // users:state.users.users,
}))(Product);