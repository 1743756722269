import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactTableBase from '../../../../../../shared/components/table/ReactTableBase';
import {connect} from "react-redux";

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DataReactTable = ({ reactTableData, dispatch, data, features, links }) => {
  const [rows, setData] = useState(links);
  const [isResizable, setIsResizable] = useState(false);
  const [isSortable, setIsSortable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(false);
    console.log(links);

  useEffect(()=>{
      if(typeof links !== 'undefined' && links !== null)
          setData(links)
console.log(links);
  },[links]);

  const tableConfig = {
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  return (
      <div>
        <ReactTableBase
          key={withSearchEngine || isResizable ? 'modified' : 'common'}
          columns={reactTableData.tableHeaderData}
          data={rows}
          tableConfig={tableConfig}
        />
      </div>
  );
};

DataReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default connect(state => ({
    data:state.product.productData,
    features:state.product.features,
    links:state.product.links,
}))(DataReactTable);