import { handleActions } from 'redux-actions';
import {
    setProducts,
} from '../actions/productsActions';

const defaultState = {
    loadedProducts: null,
};

export default handleActions(
  {
      [setProducts](state, { payload }) {
          return { ...state, loadedProducts: payload };
      }
  },
  defaultState,
);
